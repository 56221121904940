import getApiInstance from '@/services/Api.ts'

const api = getApiInstance()

export type moduleType =
  | 'all'
  | 'insurance'
  | 'deductible-contributions'
  | 'salary-sacrifice'
  | 'super-investments'
  | 'debt'

export type ReportingPostParams = {
  whitelabel_id: number
  start_date: string
  end_date: string
}

export type VerificationReportParams = {
  whitelabel_id: number
}

export interface PeriodMetrics extends ReportingPostParams {
  frequency: string
}

export default {
  getOverviewMetrics: (params: ReportingPostParams) => {
    return api.Post('callcentre/reports/overview', params)
  },
  getMemberEngagement: (params: ReportingPostParams) => {
    return api.Post('callcentre/reports/engagement', params)
  },
  getEngagementGraphMetricsByPeriodAndType: (moduleType: moduleType, params: PeriodMetrics) => {
    return api.Post(`callcentre/reports/engagement/${moduleType}`, params)
  },
  getMemberGrowthByPeriod: (params: PeriodMetrics) => {
    return api.Post('callcentre/reports/members', params)
  },
  getVerificationMetrics: (params: VerificationReportParams) => {
    return api.Post('v3/callcentre/reporting/verifications', params)
  },
  getWhitelabelOptions: () => {
    return api.Get('v3/callcentre/reporting/whitelabels')
  }
}
