<template>
  <base-view>
    <div id="advice-practices" v-if="pageContent?.currentPage === 'advicePracticesPage'">
      <Breadcrumbs />
      <LandingPageModule
        class="items-center"
        :landing-page-content="pageContent.advicePracticesHero"
        :show-button="true"
        @button-clicked="showContactUsModal"
        background-color="blue-5">
        <template #svgComponent>
          <img
            class="max-w-[300px] md:max-w-[25%] mx-auto"
            src="../../../../assets/img/finadvice.png"
            alt="financial advice" />
        </template>
      </LandingPageModule>
      <RegularCurve fill="otivo-blue" class="bg-blue-5 h-6 w-full" />
      <div class="bg-otivo-blue text-white">
        <div
          class="md:py-[80px] m-auto md:w-[1500px] flex flex-col md:flex-row justify-center items-center gap-14">
          <img
            class="h-[225px] md:h-[340px] w-[245px] md:w-[380px]"
            src="@/assets/img/fin-advice-phone.png"
            alt="fin-advice-phone-image" />
          <div class="flex flex-col justify-center p-6 md:p-6">
            <h3 class="md:w-[600px]" v-html="pageContent.advicePracticesImageAndText.heading" />
            <div class="intro-1 mt-[30px] md:w-[670px]">
              {{ pageContent.advicePracticesImageAndText.subHeading }}
            </div>
            <div class="intro-1 !font-bold mt-[30px]">
              {{ pageContent.advicePracticesImageAndText.body }}
            </div>
          </div>
        </div>
      </div>
      <RegularCurve fill="white" class="bg-otivo-blue h-6 w-full" />
      <USPs
        @button-clicked="showContactUsModal"
        class="py-[60px] bg-white"
        :usp-data="pageContent.advicePracticesUsps"
        :icons="uspsIcons">
        <template #banner-slot>
          <div class="px-5 mx-auto">
            <div
              class="hidden lg:grid h-40 xl:h-32 mx-auto mt-20 w-full max-w-[1200px] grid-flow-col auto-cols-auto items-center overflow-hidden rounded-circle">
              <div class="flex h-full items-center bg-blue-5">
                <div class="flex h-full pl-10 py-5 pr-5 items-center text-3xl text-otivo-red">
                  10%
                </div>
                <div class="h-full py-4 items-center hidden lg:flex">
                  Current Australians with access to face-to-face advice
                </div>
                <div class="h-full">
                  <svg
                    class="h-full w-auto bg-otivo-blue fill-blue-5"
                    viewBox="0 0 57 103"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M0 0H8.64787C13.8137 0 18.6992 2.34887 21.9253 6.3835L56.0026 49.002C57.1705 50.4626 57.1705 52.5374 56.0026 53.998L21.9253 96.6165C18.6992 100.651 13.8137 103 8.64787 103H0V0Z" />
                  </svg>
                </div>
              </div>
              <div class="flex h-full items-center bg-otivo-blue text-white">
                <div class="flex h-full p-5 items-center text-3xl">10%</div>
                <div class="h-full py-4 items-center hidden lg:flex">
                  Hybrid face-to-face and Otivo advice to reach more clients
                </div>
                <div class="h-full">
                  <svg
                    class="h-full w-auto bg-blue-1 fill-otivo-blue"
                    viewBox="0 0 57 103"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M0 0H8.64787C13.8137 0 18.6992 2.34887 21.9253 6.3835L56.0026 49.002C57.1705 50.4626 57.1705 52.5374 56.0026 53.998L21.9253 96.6165C18.6992 100.651 13.8137 103 8.64787 103H0V0Z" />
                  </svg>
                </div>
              </div>
              <div class="flex h-full items-center bg-blue-1 text-white">
                <div class="flex h-full p-5 items-center text-3xl">80%</div>
                <div class="h-full py-4 pr-10 items-center hidden lg:flex">
                  Partnering with Otivo means never turning clients away.
                </div>
              </div>
            </div>
          </div>

          <div class="hidden md:flex flex-col items-center"></div>
        </template>
      </USPs>
      <div class="bg-grey-1 flex flex-col py-[60px] align-center text-white relative">
        <div class="m-auto px-6 xl:pl-80 relative">
          <img
            src="@/assets/img/looking-at-computer.png"
            alt="looking at computer"
            class="hidden xl:block absolute right-[660px] w-[760px] -bottom-[260px] z-0" />
          <div class="headline-2 xl:w-[620px]">
            “{{ pageContent.fordScottTestimonial.heading }}”
          </div>
          <div class="headline-5 mt-[30px] xl:w-[650px]">
            "{{ pageContent.fordScottTestimonial.subHeading }}"
          </div>
          <div class="xl:flex justify-between align-bottom mt-[60px]">
            <div class="paragraph-2 mt-4">Tim Scott, Managing Director of Ford Scott</div>
            <img
              class="w-[110px] mt-[10px] xl:mt-0"
              src="@/assets/img/fordscott.png"
              alt="ford scott quote" />
          </div>
        </div>
      </div>
      <HowItWorksModule
        class="bg-blue-5 pt-[60px] z-10 relative"
        :how-it-works="pageContent.advicePracticesHowItWorks" />
      <Awards
        class="bg-blue-5 py-20 hidden md:block"
        :color="{
          textColour: 'grey-1',
          svgLineFill: 'grey-1',
          svgImageBackgroundFill: 'blue-5',
        }" />
      <RegularCurve fill="blue-5" class="bg-otivo-blue h-6 w-full" :rotate="true" />
      <BreathingPhoneSection
        class="bg-otivo-blue z-500"
        :content="pageContent.breathingPhoneComponent"
        @button-clicked="showContactUsModal" />
      <ThreeTileDisplay
        class="bg-white z-100"
        :icon-boxes="pageContent.threeTileDisplay.iterableItems" />
      <RegularCurve class="h-6 w-full md:hidden bg-white fill-midnight" fill="midnight" />
      <HowWeWorkItOutModal
        @close="showHowWeWorkItOutModal = false"
        v-if="showHowWeWorkItOutModal" />
    </div>
  </base-view>
</template>

<script setup lang="ts">
import Awards from '@/components/Footer/Awards.vue'
import BaseView from '@/views/BaseView.vue'
import Breadcrumbs from '@/components/Breadcrumbs/Breadcrumbs.vue'
import HowItWorksModule from '@/components/HowItWorksModule/HowItWorksModule.vue'
import HowWeWorkItOutModal from '@/components/BrandComponents/Otivo/HowWeWorkItOutModal.vue'
import LandingPageModule from '@/components/BrandComponents/LandingPageModule.vue'
import ThreeTileDisplay from '@/components/BrandComponents/Otivo/ThreeTileDisplay.vue'
import USPs from '@/components/BrandComponents/Otivo/USPs.vue'
import { computed, onBeforeMount, ref } from 'vue'
import { useStore } from 'vuex'

import BankSVG from '@/components/SVGS/BrandIcons/16px/bank.svg'
import IncomeSVG from '@/components/SVGS/BrandIcons/16px/income-and-expenses.svg'
import CashflowSVG from '@/components/SVGS/BrandIcons/16px/cashflow.svg'
import PartnersSVG from '@/components/SVGS/BrandIcons/16px/partners.svg'
import BriefingSVG from '@/components/SVGS/BrandIcons/16px/premium-briefing-plan.svg'
import PapersSVG from '@/components/SVGS/BrandIcons/16px/media-newspaper.svg'
import { useHead } from '@unhead/vue'

import RegularCurve from '@/components/Shapes/RegularCurve.vue'
import BreathingPhoneSection from '@/components/BreathingPhoneSection.vue'

const store = useStore()

useHead({
  title: "Otivo's digital advice solutions for advice practices",
})

onBeforeMount(() => store.dispatch('ContentStore/getContent', 'advicePracticesPage'))
const pageContent = computed(() => store.getters['ContentStore/pageContent'])
const showHowWeWorkItOutModal = ref(false)
const bannerActive = ref(0)
const bannerSelect = (num) => {
  if (window.innerWidth <= 1024) {
    if (bannerActive.value === num) bannerActive.value = 0
    else bannerActive.value = num
  }
}
const showContactUsModal = () => {
  store.dispatch('renderContactUsModal', true)
}
const uspsIcons: Array<unknown> = [
  IncomeSVG,
  BankSVG,
  CashflowSVG,
  PartnersSVG,
  BriefingSVG,
  PapersSVG,
]
</script>
