<template>
  <div ref="target" class="flex flex-col transition-all transform duration-1000">
    <div
      class="relative flex flex-row gap-[20px] w-100% justify-end cursor-pointer"
      @click="() => (open = !open)">
      <div
        tabindex="0"
        @keydown.enter="() => (open = !open)"
        class="px-[5px] py-[6px] rounded-[4px] self-center bg-blue-5 transition-colors transform focus:outline-otivo-blue focus:otivo-2px-shadow"
        :class="open ? 'bg-otivo-blue text-white' : 'bg-blue-5 text-otivo-blue'">
        <span class="button-4">{{ selectedPeriod }}</span>
      </div>

      <div class="flex items-center transition-colors transform" v-if="selectedPeriod !== 'Custom'">
        <span class="button-3" :class="open ? 'text-otivo-blue' : 'text-grey-1'">{{
          dateRange
        }}</span>
      </div>

      <div class="flex items-center" @click.stop v-if="selectedPeriod === 'Custom'">
        <BaseDateInput
          name="start"
          :value="customDates.start"
          @update:value="(val) => (customDates.start = val)" />
        <div class="flex w-[12px] h-[1px] bg-grey-3 mx-[5px]"></div>
        <BaseDateInput
          name="end"
          :value="customDates.end"
          @update:value="(val) => (customDates.end = val)" />
      </div>

      <div class="flex self-center">
        <ArrowWide
          :class="open ? 'transition transform rotate-0' : 'transition transform rotate-180'"
          class="w-[14px]" />
      </div>
    </div>
    <div
      tabindex="0"
      v-if="open"
      class="absolute z-90 top-[100%] w-[200px] py-[6px] px-[10px] gap-[6px] flex flex-col rounded-[5px] border-[2px] border-otivo-blue otivo-2px-shadow bg-white">
      <div
        tabindex="0"
        v-for="(option, index) in dropdownOptions"
        @keydown.enter="setPeriod(option)"
        @click="setPeriod(option)"
        :key="index"
        class="rounded-[5px] py-[6px] bg-white text-grey-1 hover:text-otivo-blue hover:bg-blue-5 px-[10px] paragraph-2">
        {{ option.key }}
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { reactive, ref, watch, watchEffect } from 'vue'
import ArrowWide from '@/components/icons/ArrowWide.vue'
import PeriodHelpers from '@/composables/PeriodHelpers.ts'
import BaseDateInput from '@/components/Inputs/BaseDateInput.vue'
import { format, parse } from 'date-fns'
import { onClickOutside, useDebounceFn } from '@vueuse/core'
import { isValid } from 'date-fns/isValid'

export type DateRange = {
  start: string
  end: string
  frequency: "daily" | "weekly" | "monthly" // this may not be the best place for this...
}

const selectedPeriod = ref('Today')
const dateRange = ref(`${PeriodHelpers.today().formatted}`)
const searchRange = reactive<DateRange>({
  start: '',
  end: '',
  frequency: 'weekly', // default
})

const customDates = reactive<DateRange>({
  start: '',
  end: '',
  frequency: 'weekly', // weekly default for custom, need UI element to set/update this
})

watch(customDates, () => {
  if (customDates.start !== '' && customDates.end !== '') {
    searchRange.start = format(customDates.start, 'dd MMM yyyy')
    searchRange.end = format(customDates.end, 'dd MMM yyyy')
    //todo add freq
  }
})

const resetCustomDates = () => {
  customDates.start = ''
  customDates.end = ''
  customDates.frequency = 'weekly'
}

const emits = defineEmits({
  'update-range': (range: DateRange) => range,
})

watchEffect(() => {
  if (searchRange.start && searchRange.end) {
    emits('update-range', searchRange)
  }
})
const setPeriod = (period: {
  key: string
  func: () => { start: string; end: string; formatted: string } | null
  frequency: 'daily' | 'weekly' | 'monthly'
}) => {
  open.value = false
  selectedPeriod.value = period.key
  if (period.key === 'Custom') return
  resetCustomDates()
  dateRange.value = `${period.func.formatted}`
  searchRange.start = period.func.start
  searchRange.end = period.func.end
  searchRange.frequency = period.frequency
}

const open = ref(false)
const target = ref()
onClickOutside(target, () => {
  open.value = false
})
const dropdownOptions = [
  { key: 'Today', func: PeriodHelpers.today(), frequency: 'daily' },
  { key: 'Yesterday', func: PeriodHelpers.yesterday(), frequency: 'daily' },
  { key: 'This week (Sun - Today)', func: PeriodHelpers.thisWeek(), frequency: 'daily' },
  { key: 'Last week (Sun - Sat)', func: PeriodHelpers.lastWeek(), frequency: 'daily' },
  { key: 'Last 7 days', func: PeriodHelpers.last7Days(), frequency: 'daily' },
  { key: 'Last 28 days', func: PeriodHelpers.last28Days(), frequency: 'daily' },
  { key: 'Last 30 days', func: PeriodHelpers.last30Days(), frequency: 'weekly' },
  { key: 'Last 90 days', func: PeriodHelpers.last90Days(), frequency: 'weekly' },
  { key: 'Last 12 months', func: PeriodHelpers.last12Months(), frequency: 'monthly' },
  { key: 'Last calendar year', func: PeriodHelpers.lastCalendarYear(), frequency: 'monthly' },
  { key: 'This year (Jan - Today)', func: PeriodHelpers.thisYear(), frequency: 'monthly' },
  { key: 'Custom', func: null },
]
</script>

<style scoped></style>
