<template>
  <div id="how-otivo-works" class="relative overflow-hidden bg-otivo-blue flex flex-col">
    <div
      class="relative z-20 text-center px-4 lg:mx-auto lg:max-w-[1100px]"
      :class="`text-${textColour}`">
      <div class="lg:w-1000px m-auto px-6 lg:px-0">
        <h3 v-html="howOtivoWorksContent.heading" />
        <h4 class="intro-1 mt-[50px]" v-html="howOtivoWorksContent.subHeading" />
        <slot name="modal" />
      </div>
      <div class="lg:grid lg:grid-cols-12 gap-10 m-auto">
        <div id="phone" class="lg:col-span-6 hidden lg:flex items-center relative z-30">
          <div v-for="(icon, iconIndex) in icons" :key="iconIndex" class="object-contain">
            <img
              v-show="selectedTab === iconIndex"
              class="mt-12 pr-5 mx-auto z-30 inset-0 w-auto h-full"
              :src="icon.image"
              alt="Components displayed on Phone"
              :key="'key' + selectedTab" />
          </div>
        </div>

        <div class="flex flex-col items-center mt-20 col-span-6">
          <div class="w-100% space-y-8 lg:space-y-0">
            <!-- how-rows -->
            <div
              :key="index"
              v-for="(sectionTab, index) in howOtivoWorksContent.iterableItems"
              @click="selectedTab = index"
              class="transition-all ease-in-out">
              <div
                :class="[mdScreen && rowActive(index) ? `bg-blue-1` : null]"
                class="hover:bg-[#004ED0] group md:group-hover:bg-blue-1 m-auto md:max-w-[600px] flex flex-row bg:none items-start gap-4 text-left pointer-events-none md:pointer-events-auto md:cursor-pointer md:p-4 rounded-xl">
                <div
                  class="flex items-center rounded-circle px-6 aspect-square transition-all duration-300 ease-in"
                  :class="showActiveIcon(index) ? 'bg-white' : 'bg-white sm:bg-blue-1'"
                  :key="`circle-${index}`">
                  <div class="relative w-16 h-16 p-2">
                    <img
                      :class="{ 'icon-white': !showActiveIcon(index) }"
                      :src="iconSrc(index)"
                      :alt="altSrc(index)" />
                  </div>
                </div>
                <div
                  class="flex flex-col gap-4 ml-4 mr-4 lg:mr-8 transition-all"
                  :class="`text-${tableTextColour}`">
                  <h6 class="min-w-26%">{{ sectionTab.heading }}</h6>
                  <p class="paragraph-1">
                    {{ sectionTab.body }}
                  </p>
                </div>
              </div>
              <section
                v-if="mdScreen && rowActive(index)"
                class="hidden lg:flex lg:hidden m-auto w-[400px] h-[620px]"
                id="phone-tablet">
                <div v-for="(icon, iconIndex) in icons" :key="iconIndex">
                  <img
                    v-show="selectedTab === iconIndex"
                    class="mt-12 mx-auto z-30 pt-5"
                    :src="icon.image"
                    alt="Components displayed on Phone"
                    :key="'key' + selectedTab" />
                </div>
              </section>
            </div>
            <!-- /how-rows -->
          </div>
        </div>
      </div>
      <div class="w-full max-w-[1000px] flex flex-col items-center mt-10 self mx-auto">
        <p class="paragraph-1 text-left m-auto">
          And because it all lives online, Otivo automatically updates its advice to whatever new is
          happening in your world.
        </p>
        <span class="mt-10">
          <slot class="" name="buttons" />
        </span>
      </div>
    </div>
    <HowWeWorkItOutModal
      id="how-we-work-it-out-modal"
      @close="toggleWorkedOutModal"
      v-if="workedOutModalOpen" />
  </div>
</template>

<script lang="ts" setup>
import HowWeWorkItOutModal from '@/components/BrandComponents/Otivo/HowWeWorkItOutModal.vue'
import { computed, onMounted, ref } from 'vue'
import { IterableItemDefaultType } from '@/types/globalComponents/IterableItemDefaultType'

import DebtOtivo from '@/components/SVGS/BrandIcons/16px/debt.svg?url'
import UmbrellaOtivo from '@/components/SVGS/BrandIcons/16px/insurance.svg?url'
import MoneyOtivo from '@/components/SVGS/BrandIcons/16px/money.svg?url'
import SunOtivo from '@/components/SVGS/BrandIcons/SunOtivo.svg?url'
import MonitorOtivo from '@/components/SVGS/BrandIcons/16px/monitor.svg?url'
import DebtPhone from '@/assets/img/phones/debt-phone.webp?url'
import IncomePhone from '@/assets/img/phones/income-phone.webp?url'
import InsurancePhone from '@/assets/img/phones/insurance-phone.webp?url'
import MaximisePhone from '@/assets/img/phones/maximise-phone.webp?url'
import RetirePhone from '@/assets/img/phones/retire-phone.webp?url'

import { useMediaQuery } from '@vueuse/core'

type HowOtivoWorksType = {
  heading: string
  subHeading: string
  iterableItems: Array<IterableItemDefaultType>
}

interface Props {
  howOtivoWorksContent: HowOtivoWorksType
  textColour?: string
  tableTextColour?: string
  hoverColour?: string
  iconColour?: string
}

const workedOutModalOpen = ref(false)
const selectedTab = ref<number>(0)

const toggleWorkedOutModal = () => {
  workedOutModalOpen.value = !workedOutModalOpen.value
}

const rowActive = (index: number) => selectedTab.value == index
const mdScreen = useMediaQuery('(min-width: 768px)')
const showActiveIcon = (index: number) => !mdScreen.value || rowActive(index)

// these are needed to fix issues when content is added to the backend but the icons array doesnt have enough matching values. I hate this
const iconSrc = (index: number) => {
  const validIndex = index >= 0 && index < icons.value.length ? index : 0
  return icons.value[validIndex].svg
}
const altSrc = (index: number) => {
  const validIndex = index >= 0 && index < icons.value.length ? index : 0
  return icons.value[validIndex].alt
}

withDefaults(defineProps<Props>(), {
  textColour: 'white',
  tableTextColour: 'white',
  hoverColour: 'blue-1',
  iconColour: 'white',
})
const icons = computed(() => {
  return [
    {
      alt: 'An icon of an umbrella',
      svg: UmbrellaOtivo,
      image: InsurancePhone,
    },
    {
      alt: 'An icon of a bag of money',
      svg: MoneyOtivo,
      image: MaximisePhone,
    },
    {
      alt: 'An icon of a sun',
      svg: SunOtivo,
      image: RetirePhone,
    },
    {
      alt: 'An icon of a a line chart on a monitor',
      svg: DebtOtivo,
      image: DebtPhone,
    },
    {
      alt: 'An icon of a a line chart on a monitor',
      svg: MonitorOtivo,
      image: IncomePhone,
    },
  ]
})

const preloadPhones = () => {
  icons.value.forEach((icon) => {
    const img = new Image()
    img.src = icon.image
  })
}

onMounted(() => {
  preloadPhones()
})
</script>

<style lang="scss" scoped>
@use '@/assets/css/variables.scss';

.phone-border {
  border-radius: 30px;
  border: 10px solid var(--blue-1);
  position: relative;
  overflow: hidden;
}

.icon-white {
  filter: saturate(0) brightness(0) invert(1);
}
</style>
