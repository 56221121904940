<template>
  <div class="flex flex-col gap-[20px]" :key="updateKey">
    <div
      :data-test="`question-${id}-row-${idx}`"
      :data-row="idx"
      class="flex flex-col md:flex-row gap-[15px] justify-center"
      :key="idx"
      v-for="(row, idx) in dataRows"
      v-show="!row.delete">
      <div
        class="flex flex-row justify-between md:flex-col align-middle"
        v-for="(question, questionIdx) in row.questions"
        :key="question.id">
        <p
          class="button-2 self-center md:self-start max-w-[150px] text-center"
          :class="question.component === 'dropdown' ? 'hidden md:block' : ''">
          {{ question.title }}
        </p>
        <component
          class="mt-[10px]"
          :class="`${question.component}-class`"
          @update:value="(val: unknown) => updateRow(idx, question, val)"
          @update:checked="(val: unknown) => updateRow(idx, question, val)"
          @toggle="(val: unknown) => updateRow(idx, question, val)"
          @selected="(val: unknown) => updateRow(idx, question, val)"
          :is="componentImports[question.component]"
          v-bind="row.props[questionIdx]" />
      </div>
      <div
        v-if="!static"
        class="flex flex-row-reverse md:flex-col md:self-end justify-evenly md:justify-center h-[49px] gap-[10px] cursor-pointer">
        <div
          :tabindex="0"
          class="button-2 text-red-dark flex flex-col items-center gap-[2px] w-full"
          @click="deleteRow(idx, row)"
          @keyup.enter="deleteRow(idx, row)">
          Remove
          <SubtractionIcon fill="red-dark" class="h-[18px] w-[18px]" />
        </div>
      </div>
    </div>
    <div class="justify-center flex" v-if="!static">
      <div
        :tabindex="0"
        class="cursor-pointer text-otivo-blue button-2 flex items-center gap-[10px]"
        @click="mapNewRow()"
        @keyup.enter="mapNewRow()">
        <AdditionIcon fill="#0064FF" /> Add more
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { InputItem } from '@/types/InputItem.ts'
import { GenericObject } from '@/types/GlobalTypes.ts'
import BaseInput, { BaseInputProps } from '@/components/Inputs/BaseInput.vue'
import Dropdown from '@/components/BasicInputs/Dropdown.vue'
import { computed, onMounted, onUnmounted, reactive, ref, watch } from 'vue'
import { Answer, TableQuestion } from './Table.type.ts'
import BaseCheckbox from '@/components/Inputs/BaseCheckbox.vue'
import AdditionIcon from '@/components/icons/AdditionIcon.vue'
import SubtractionIcon from '@/components/icons/SubtractionIcon.vue'
import PercentageInput from '@/components/BasicInputs/PercentageInput.vue'

/**
 * TODO: refactor this internal shape to be an array of objects that represent each question, not the row
 */
type Props = {
  id: number
  questions: Array<TableQuestion>
  existingAnswers?: Array<Array<Answer>>
  static: boolean
}
export type rowObject = {
  questions: TableQuestion[]
  props: { [key: string | number]: unknown }
  data: {
    [key: string | number]: unknown
  }
  delete: boolean
}
const props = defineProps<Props>()
const emits = defineEmits({
  'update:value': (answer: Array<{ [key: string | number]: unknown }>) => answer,
})
const dataRows = reactive<rowObject[]>([])
const validRows = computed(() => {
  const filtered = dataRows.filter((row: rowObject) => !row.delete)
  return {
    questionId: props.id,
    answer: filtered.map((row: rowObject) => {
      return row.data
    }),
  }
})
defineExpose({ validRows })

watch(dataRows, () => {
  emits('update:value', validRows.value.answer)
})

const updateKey = ref(0)
onMounted(() => {
  if (props.existingAnswers?.length) mapExistingRows()
  else mapNewRow()
})

const mapComponentProps = async () => {
  const promises = []
  for (let row of dataRows) {
    promises.push(
      new Promise((resolve) => {
        for (let question of row.questions) {
          const questionIndex = row.questions.indexOf(question)
          row.props[questionIndex] = getComponentProperties(
            dataRows.indexOf(row),
            questionIndex,
            question,
          )
        }
        resolve(null)
      }),
    )
  }
  await Promise.all(promises)
}
const mapExistingRows = async () => {
  if (!props.existingAnswers) return
  for (let row of props.existingAnswers) {
    const newRow = {
      questions: [] as TableQuestion[],
      data: {} as rowObject['data'],
      props: {} as rowObject['props'],
      delete: false,
    } as rowObject
    newRow.questions = props.questions
    for (let answer of row) {
      newRow.data[answer.questionId] = answer.answer
    }
    dataRows.push(newRow)
  }
  await mapComponentProps()
}
const updateRow = (rowIndex: number, question: TableQuestion, value: unknown) => {
  const row = dataRows[rowIndex]
  const index = row.questions.indexOf(question)
  const props = row.props[index] as rowObject['props']
  row.data[question.id] = value

  if (question.component === 'dropdown') props.existingItem = value
  else props.value = value
}

const deleteRow = (idx: number, row: rowObject) => {
  /**
   * TODO: implement soft delete
   */
  row.delete = true
  // dataRows.splice(idx, 1)
  updateKey.value += 1
}

const mapNewRow = () => {
  const rowIndex = dataRows.length
  // refactor shape
  const newRow = {
    questions: [] as TableQuestion[],
    data: {} as rowObject['data'],
    props: {} as rowObject['props'],
    delete: false,
  } as rowObject

  newRow.questions = props.questions

  for (let question of newRow.questions) {
    const index = newRow.questions.indexOf(question)
    newRow.props[index] = getComponentProperties(rowIndex, index, question)
    newRow.data[question.id] = question.defaultValue || undefined // set default value
  }
  dataRows.push(newRow)
}

// component mapping
const componentImports: GenericObject<unknown> = {
  input: BaseInput,
  dropdown: Dropdown,
  checkbox: BaseCheckbox,
  percentageInput: PercentageInput,
}
// prop mapping
const getComponentProperties = (rowIndex: number, index: number, question: TableQuestion) => {
  switch (question.component) {
    case 'checkbox':
    case 'input':
      return mapInputProperties(rowIndex, index, question)
    case 'percentageInput':
      return mapInputProperties(rowIndex, index, question)
    case 'dropdown':
      return mapDropdownProperties(rowIndex, index, question)
  }
}

const findMatchingAnswer = (rowIndex: number, question: TableQuestion) => {
  if (!props.existingAnswers) return null
  if (props.existingAnswers.length <= 0) return null
  if (!props.existingAnswers[rowIndex]) return null
  // get the index of the answer for the matching question
  const answerObj = props.existingAnswers[rowIndex].find(
    (answer) => answer.questionId === question.id,
  )
  const answerIndex = props.existingAnswers[rowIndex].indexOf(answerObj)
  // if there is an existing answer then use it, otherwise use the default value
  if (answerObj && answerIndex > -1) return props.existingAnswers[rowIndex][answerIndex].answer
  return question.defaultValue ?? null
}

const mapInputProperties = (
  rowIndex: number,
  index: number,
  question: TableQuestion,
): BaseInputProps => {
  let answer: string | number | null = null
  if (props.existingAnswers && props.existingAnswers[rowIndex]) {
    answer = findMatchingAnswer(rowIndex, question) as string | number | null
  } else if (question.existingValue) {
    // this could potentially be removed (not for this literal release)
    answer = question.existingValue as string | number | null
  }

  return {
    value: answer,
    name: `question-${question.id}-row-${index}-${question.inputType}`,
    type: question.inputType,
    placeholder: question.placeholderText ?? null,
  }
}

const mapDropdownProperties = (rowIndex: number, index: number, question: TableQuestion) => {
  const answer = findMatchingAnswer(rowIndex, question) as InputItem<string | number> | null

  return {
    items: question.dropdownOptions || [],
    type: `${question.id}-row-${index}-dropdown`,
    existingItem: answer,
    placeholder: question.placeholderText ?? 'Select an option',
  }
}

watch(
  () => props.existingAnswers,
  (newVal) => {
    dataRows.splice(0, dataRows.length)
    if (newVal?.length) mapExistingRows()
    else mapNewRow()
  },
)

onUnmounted(() => {
  dataRows.splice(0, dataRows.length)
})
</script>

<style scoped>
.dropdown-class {
  @apply w-100% md:w-[150px];
}

.input-class {
  @apply w-50% md:w-[105px];
}

.checkbox-class {
  @apply h-100% flex items-center justify-center;
}
</style>
