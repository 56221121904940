import '@/assets/css/animate.scss'
import '@/assets/css/borderAnimation.css'
import '@/assets/css/responsive.scss'
import '@/assets/css/tailwind.scss'
import '@/assets/css/themes/OtivoMain/theme.scss'
import 'nprogress/nprogress.css'
import 'vue-toast-notification/dist/theme-bootstrap.css'
import 'floating-vue/dist/style.css'
import FloatingVue from 'floating-vue'
import * as Sentry from '@sentry/vue'
import { createSentryPiniaPlugin } from '@sentry/vue'
import { createApp } from 'vue'
import { store } from '@/store'
import globalConstants from '@/GlobalConstants/globalConstants'
import { provideObject } from '@/lib/AuthenticatorPlugin'
import { createPinia } from 'pinia'
import { useSessionStore } from '@/store/pinia/SessionStore'
import { createHead } from 'unhead'
import { setupInstance } from '@/instances.ts'
import getBackendStatus from '@/composables/getBackendStatus.ts'
import { WhitelabelDataType } from '@/types/WhiteLabelTypes.ts'
import Vue3Toastify, { ToastContainerOptions } from 'vue3-toastify'
import 'vue3-toastify/dist/index.css'
import './assets/css/toasts.scss'
import ParseJWT from '@/composables/decryptWBGJWT.ts'
import { checkUserSymbol, provideObject as checkUserObject } from '@/composables/users/checkUser.ts'
import api from '@/services/Api.ts'
import Root from '@/Main.vue'
import initDone from '@/initDone.ts'

const pinia = createPinia()
const auth0Injection = provideObject()
const head = createHead()

const app = createApp(Root)
app.use(pinia)
app.use(head)
app.provide(auth0Injection.Auth0Symbol, auth0Injection.auth0)
pinia.use(createSentryPiniaPlugin())
Sentry.init({
  app,
  dsn: 'https://ab93577133194a0db206614ae4b9b40d@o293201.ingest.us.sentry.io/5931210',
  environment: import.meta.env.VITE_SENTRY_ENV,
  enabled: import.meta.env.VITE_SENTRY_ENABLED === 'true',
  tracePropagationTargets: [
    '*.127.0.0.1:8080',
    '*.otivo.com',
    '*.otivo.dev',
    '*.otivo.local',
    /^\//,
  ],
  tracesSampleRate: 1,
  integrations: [
    Sentry.replayIntegration(),
    Sentry.vueIntegration({ app, attachProps: true, trackComponents: true }),
  ],
})
app.mount('#app')
;(async () => {
  console.info('[INFO] Starting application')
  console.time('[TIMER] ready')
  console.time('[TIMER] app init')
  console.time('[TIMER] system/status')
  const backendStatus = await getBackendStatus()
  console.timeEnd('[TIMER] system/status')

  const adminPortalEnabled =
    backendStatus.whitelabel.callcentre_portal || backendStatus.whitelabel.admin_portal

  // note cant remember why this was removed in hydra, adding it back for now
  // check if we're impersonating then set or continue
  // getApiInstance().setImpersonationHeader()

  const instance = setupInstance(backendStatus.whitelabel.frontend_config, adminPortalEnabled)

  // Create Auth0 instance
  console.time('[TIMER] auth0 init')
  const realm = backendStatus.whitelabel.superfund_config?.users_realm
  const continueRedirectPath = window.location.pathname ?? '/'
  await auth0Injection.auth0.createClient(instance.AUTH0_ID, realm, continueRedirectPath)
  // Inject Auth0 instance
  // app.provide(auth0Injection.Auth0Symbol, auth0Injection.auth0)

  app.provide(checkUserSymbol, checkUserObject)
  console.timeEnd('[TIMER] auth0 init')
  // set store and router to app
  app.use(instance.ROUTER)
  app.use(store)

  // set global constants
  app.config.globalProperties.globalConstants = globalConstants()

  // save whitelabel data to session
  const sessionStore = useSessionStore()
  sessionStore.setWhitelabelData({
    whitelabel: backendStatus.whitelabel as WhitelabelDataType,
  })

  const params = new URLSearchParams(window.location.search)

  // scrape WBG token if it exists
  if (params.has('token')) {
    try {
      const token = await ParseJWT(params.get('token'), import.meta.env.VITE_WBGK)
      localStorage.setItem('wbg_details', JSON.stringify(token))
      history.pushState({}, '', continueRedirectPath)
    } catch (e) {
      history.pushState({}, '', continueRedirectPath)
      console.error(e)
    }
  }

  // Directives and Component Plugins
  console.time('[TIMER] plugins')
  app.use(FloatingVue, {
    themes: {
      tooltip: {
        triggers: ['hover', 'focus', 'click', 'touch'],
      },
    },
  })

  app.use(Vue3Toastify, {
    theme: 'colored',
    position: 'top-center',
    clearOnUrlChange: false,
  } as ToastContainerOptions)
  console.timeEnd('[TIMER] plugins')

  console.time('[TIMER] sentry init')
  api().setSentryTraceHeader()
  console.timeEnd('[TIMER] sentry init')
  initDone.value = true
  console.timeEnd('[TIMER] app init')
})()
