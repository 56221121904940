<template>
  <BaseView>
    <div
      id="personal-pricing"
      v-if="pageContent?.currentPage === 'personalPricingPage'"
      class="bg-blue-5">
      <Breadcrumbs />
      <PersonalPricingTiles class="relative z-0 bg-blue-5" />
      <PersonalBetterOff
        v-if="false"
        :personal-better-off-content="pageContent?.personalBetterOff" />
      <HowOtivoWorks class="py-[60px]" :how-otivo-works-content="howItWorks">
        <template #modal>
          <p class="w-[280px] pt-[20px] md:pt-0 m-auto md:w-auto intro-2 text-white">
            {{ howItWorks.tagline }}
            <span @click="showHowWeWorkItOutModal" class="underline cursor-pointer">
              {{ howItWorks.taglineLink }}
            </span>
          </p>
        </template>
        <template #buttons>
          <OtivoButton
            class="mt-[20px] w-full sm:w-auto m-auto lg:ml-0"
            colour="red"
            @click="auth0.createAccount"
            data-test="homeBtnTrial">
            Start free trial
          </OtivoButton>
        </template>
      </HowOtivoWorks>
      <RegularCurve :rotate="true" class="min-h-6 w-full" fill="otivo-blue" />
      <!--      Commented out until there's an answer from Brand/Design about the videos-->
      <div class="bg-blue-5 my-10 md:my-20 flex flex-col items-center w-full">
        <h3>Simple and practical advice to help you be better off</h3>
        <div class="pt-5 md:pt-10 w-full md:w-1/2 flex flex-col md:flex-row gap-5 md:gap-10">
          <div
            class="cursor-pointer relative bg-blue-5 rounded-t"
            @click="playPauseVideo('super-investment-video')">
            <img
              id="playButton-super-investment-video"
              alt="Play Button"
              class="cursor-pointer absolute m-auto left-0 right-0 top-0 bottom-0"
              src="@/assets/img/super-investments-thumbnail.webp" />
            <video
              id="mediaVideo-super-investment-video"
              muted
              controls
              class="max-w-full h-auto rounded-sm object-cover"
              type="video/webm"
              src="https://store.otivo.com/Videos/understanding_Investments_720.mp4" />
          </div>
          <div
            class="cursor-pointer relative bg-blue-5 rounded-t"
            @click="playPauseVideo('salary-sac-video')">
            <img
              :id="'playButton-salary-sac-video'"
              alt="Play Button"
              class="cursor-pointer absolute m-auto left-0 right-0 top-0 bottom-0"
              src="@/assets/img/super-contributions-thumb.webp" />
            <video
              id="mediaVideo-salary-sac-video"
              muted
              controls
              class="max-w-full h-auto rounded-sm object-cover"
              type="video/webm"
              src="https://store.otivo.com/Videos/understanding_contributions_720.mp4" />
          </div>
        </div>
      </div>
      <RegularCurve class="min-h-6 w-full" fill="blue-1" />
      <HowItWorksModule
        class="py-16 px-5 md:px-0 relative z-50 bg-blue-1 text-white"
        :body-text-colour="'white'"
        :lines="'blue-2'"
        :how-it-works="pageContent.personalPricingHowItWorks" />
      <Awards
        class="pt-10 pb-[80px] bg-grey-1 md:bg-blue-1 w-full"
        :color="{
          textColour: 'white',
          svgLineFill: 'white',
          svgImageBackgroundFill: 'blue-1',
        }" />
      <ThreeTileDisplay
        class="!bg-blue-1 md:!bg-grey-logo"
        text-colour="white"
        :icon-boxes="pageContent.threeTileDisplay.iterableItems" />
      <RegularCurve class="h-6 w-full md:hidden bg-blue-1 fill-midnight" fill="midnight" />
    </div>
  </BaseView>
</template>

<script setup lang="ts">
import Awards from '@/components/Footer/Awards.vue'
import BaseView from '@/views/BaseView.vue'
import Breadcrumbs from '@/components/Breadcrumbs/Breadcrumbs.vue'
import HowItWorksModule from '@/components/HowItWorksModule/HowItWorksModule.vue'
import PersonalBetterOff from '@/components/BrandComponents/Otivo/Personal/PersonalBetterOff.vue'
import PersonalPricingTiles from '@/components/BrandComponents/Pricing/PersonalPricingTiles.vue'

import { computed, onBeforeMount } from 'vue'
import { useStore } from 'vuex'
import ThreeTileDisplay from '@/components/BrandComponents/Otivo/ThreeTileDisplay.vue'
import RegularCurve from '@/components/Shapes/RegularCurve.vue'
import { useHead } from '@unhead/vue'
import HowOtivoWorks from '@/components/BrandComponents/Otivo/HowItWorks/HowOtivoWorks.vue'
import OtivoButton from '@/components/OtivoButton.vue'
import HowWeWorkItOutModal from '@/components/BrandComponents/Otivo/HowWeWorkItOutModal.vue'
import { useModalStore } from '@/store/pinia/ModalStore.ts'
import { useAuth0 } from '@/lib/AuthenticatorPlugin.ts'

const store = useStore()
const auth0 = useAuth0()
onBeforeMount(() => store.dispatch('ContentStore/getContent', 'personalPricingPage'))

useHead({
  title: "Otivo's affordable digital advice for everyone",
})

const playPauseVideo = (title: string) => {
  const video = document.getElementById('mediaVideo-' + title) as HTMLVideoElement
  const playButton = document.getElementById('playButton-' + title)
  video.controls = true
  if (video.paused) playButton?.classList.add('hidden')
  video.play()
}

const modalStore = useModalStore()
const showHowWeWorkItOutModal = () => {
  modalStore.openModal(HowWeWorkItOutModal, {})
}

const howItWorks = {
  heading: 'Financial advice at your <span class="svg-underline-wide">fingertips</span>',
  subHeading:
    'Otivo helps thousands of Australians be better off by an average of <span class="font-bold">$371K</span> at retirement.',
  tagline: 'Yep, you read that right.',
  taglineLink: 'Here’s how we do it.',
  footer:
    'And because it all lives online, Otivo’s advice automatically updates to whatever new is happening in your world.',
  buttonText: 'Get started',
  iterableItems: [
    {
      heading: 'Protect the things you love',
      body: "We'll help you get the personal insurance cover you need for peace of mind.",
    },
    {
      heading: 'Maximise your super growth',
      body: "We'll calculate how much you can comfortably contribute each year to super without hitting your caps.",
    },
    {
      heading: 'Retire years earlier',
      body: 'We’ll project what your future looks like, then help you optimise your super so you can retire in style.',
    },
    {
      heading: 'Pay down your debts faster',
      body: 'We’ll let you know which debts to tackle first, then show you how to pay them down faster.',
    },
    {
      heading: 'Keep track of your money',
      body: 'Otivo also comes with heaps of easy-to-understand resources to help you stay on top of your finances.',
    },
  ],
}

const pageContent = computed(() => store.getters['ContentStore/pageContent'])
</script>
