<template>
  <div>
    <h3 class="text-blue-1">Changing your details will impact your advice</h3>
    <p class="paragraph-1 mt-[40px]">
      Updating the details about this {{ liability.type === 'credit_card' ? 'card' : 'loan' }} will
      reset your advice on your other cards and loans. Please confirm you are happy to proceed.
    </p>

    <div class="flex flex-col md:flex-row gap-[20px] mt-[40px]">
      <OtivoButton class="w-full md:w-auto" data-test="debtLeaveWithoutSaving" colour="white" @click="leaveWithoutSaving"
        >Leave without saving</OtivoButton
      >
      <OtivoButton class="w-full md:w-auto" data-test="debtSaveAndReset" @click="updateLiability"
        >Save and reset advice</OtivoButton
      >
    </div>
  </div>
</template>

<script setup lang="ts">
import { Liability } from '@/types/Liability.ts'
import OtivoButton from '@/components/OtivoButton.vue'
import { useRouter } from 'vue-router'
import { useModalStore } from '@/store/pinia/ModalStore.ts'

const router = useRouter()
const modalStore = useModalStore()

const leaveWithoutSaving = () => {
  router.push({ name: 'debtPlan' })
  modalStore.closeModal()
}

interface Props {
  liability: Liability
  updateLiability: () => void
}
defineProps<Props>()
</script>

<style scoped></style>
