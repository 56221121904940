import { ref } from 'vue'
import { defineStore } from 'pinia'
import ContentService from '@/services/ContentService.ts'
import UserService from '@/services/UserService'
import { cloneObject, convertToKebabCase } from '@/utilities.ts'

export interface IAccountFlagsStore {
  getFlag: (flag: FlagKey, value?: string) => FlagType | GenericNotificationsType
  accountFlags: Record<FlagKey, FlagType | GenericNotificationsType>
  fetchAccountFlags: () => Promise<void>
  updateAccountFlags: (flag: FlagKey, subFlagKey?: string) => Promise<void>
  reset: () => void
}

export type FlagType = {
  acknowledged_disclaimer?: string | null
  questions_completed?: string | null
  [key: string]: string | null | undefined // This allows for additional dynamic properties
}

export type GenericNotificationsType = {
  [key: string]: string | null
}

export type FlagKey =
  | 'superInvestments'
  | 'salarySacrificeContributions'
  | 'lumpSumConcessionalContributions'
  | 'lumpSumNonConcessionalContributions'
  | 'personalInsurance'
  | 'debtPlan'
  | 'genericQuestions'
  | 'appNotifications'
  | 'subscriptionRenewalConsent'
  | 'guidance_client_consent_email_sent'

export const useAccountFlagsStore = defineStore('accountFlagsStore', () => {
  const emptyFlags: Record<FlagKey, FlagType | GenericNotificationsType | boolean> = {
    superInvestments: {
      acknowledged_disclaimer: null,
    },
    salarySacrificeContributions: {
      acknowledged_disclaimer: null,
      questions_completed: null,
    },
    lumpSumConcessionalContributions: {
      acknowledged_disclaimer: null,
      questions_completed: null,
    },
    lumpSumNonConcessionalContributions: {
      acknowledged_disclaimer: null,
      questions_completed: null,
    },
    personalInsurance: {
      acknowledged_disclaimer: null,
      questions_completed: null,
    },
    debtPlan: {
      acknowledged_disclaimer: null,
      questions_completed: null,
    },
    genericQuestions: {
      questions_completed: null,
    },
    appNotifications: {
      has_seen_july_1st_notification: null,
    },
    subscriptionRenewalConsent: {
      acknowledged_disclaimer: null,
    },
    guidance_client_consent_email_sent: false,
  }

  const accountFlags = ref<Record<FlagKey, FlagType | GenericNotificationsType>>(emptyFlags)

  const reset = () => {
    accountFlags.value = cloneObject(emptyFlags)
  }

  const getFlag = (flag: FlagKey, value = ''): FlagType | GenericNotificationsType => {
    if (value) {
      return accountFlags.value[flag][value]
    }
    return accountFlags.value[flag]
  }

  const fetchAccountFlags = async () => {
    return new Promise(async (resolve) => {
      try {
        const response = await ContentService.getAccountFlags()
        accountFlags.value = { ...response?.data, ...response?.data?.flags }
      } catch (error) {
        console.error(error)
      } finally {
        resolve()
      }
    })
  }

  const updateAccountFlags = async (flag: FlagKey, subFlagKey?: string) => {
    const cleanedFlag = convertToKebabCase(flag)
    let updatedFlag

    if (subFlagKey) {
      updatedFlag = {
        [cleanedFlag]: {
          ...accountFlags.value[flag],
          [subFlagKey]: new Date().toISOString(),
        },
      }
    } else {
      updatedFlag = {
        [cleanedFlag]: {
          acknowledged_disclaimer: new Date().toISOString(),
        },
      }
    }
    const response = await UserService.UpdateDisclaimerFlags(updatedFlag)
    const flags = { ...response?.data, ...response?.data?.flags }
    accountFlags.value = flags || {}
  }

  return {
    getFlag,
    accountFlags,
    fetchAccountFlags,
    updateAccountFlags,
    reset,
  }
})
