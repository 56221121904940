<template>
  <div id="leadership-team" class="bg-blue-5 mt-10">
    <div class="fix-width-m-auto md:text-center px-[20px]">
      <h3 class="pb-8 text-grey-1">A leadership team with a wealth of experience</h3>
      <p class="intro-1 text-grey-1 max-w-[800px] m-auto">
        With expertise in financial advice, technology, banking, advertising, and media, Otivo’s
        leadership team are united by a common goal to help Australians be better off.
      </p>
      <div class="flex flex-row m-auto justify-center text-center gap-[30px] py-10 flex-wrap">
        <ProfileCard
          class="w-[240px]"
          v-for="person in leadershipTeam"
          :profile="person"
          :key="person.name" />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import Catherine from '@//components/BrandComponents/ExtraContent/Profiles/CatherineMulholland.webp?url'
import Joanna from '@//components/BrandComponents/ExtraContent/Profiles/JoannaLawson.webp?url'
import Nathan from '@//components/BrandComponents/ExtraContent/Profiles/NathanIsterling.webp?url'
import Phillipa from '@//components/BrandComponents/ExtraContent/Profiles/PhillipaBillings.webp?url'
import Rob from '@//components/BrandComponents/ExtraContent/Profiles/RobMorrison.webp?url'
import Rubens from '@//components/BrandComponents/ExtraContent/Profiles/RubensPeculis.webp?url'
import Paul from '@//components/BrandComponents/ExtraContent/Profiles/PaulFeeney.webp?url'
import ProfileCard from '@/components/BrandComponents/ExtraContent/ProfileCard.vue'

const leadershipTeam = [
  {
    image: Paul,
    name: 'Paul Feeney',
    position: 'Founder + CEO',
    blurb:
      'Paul has led successful financial businesses all around the globe. He’s a Responsible Manager, an ASIC-registered financial adviser and a qualified tax relevant provider. Paul’s dedication to helping people is at the heart of Otivo.',
  },
  {
    image: Nathan,
    name: 'Nathan Isterling',
    position: 'Chief Information Officer',
    blurb:
      'With 25 years of experience under his belt, Nathan was behind footytips.com and Punters, as well as being CEO for tech teams, including leading ESPN digital. Nathan’s tech know-how drives Otivo forward.',
  },
  {
    image: Rob,
    name: 'Rob Morrison',
    position: 'Chief Marketing Officer',
    blurb:
      "Working for big brands like Vodafone, 3 mobile, and Foxtel, Rob has 30 years of experience leading marketing, brand, and creative teams. He's passionate about showing how Otivo can make a difference in people's lives.",
  },
  {
    image: Phillipa,
    name: 'Phillipa Billings',
    position: 'Chief Advice Officer',
    blurb:
      "Philippa is a Responsible Manager, an ASIC-registered financial adviser and a qualified tax-relevant provider. With over 25 years in the financial sector, Philippa's technical expertise in investment research and compliance helps make our advice top-notch.",
  },
  {
    image: Catherine,
    name: 'Catherine Mulholland',
    position: 'Head of Key Accounts',
    blurb:
      'With 30+ years in financial services, Catherine has held significant roles at IOOF and MLC Australia. Her experience in mergers and acquisitions helps us forge strong relationships.',
    classes: 'col-start-2',
  },
  {
    image: Rubens,
    name: 'Rubens Peculis',
    position: 'Chief Technology Officer',
    blurb:
      'Rubens is an innovative technologist with experience leading teams at Tribal DDB Sydney, Destination NSW, and the Australian National University. His passion for tech drives Otivo ahead of the game.',
  },
  {
    image: Joanna,
    name: 'Joanna Lawson',
    position: 'Head of Product',
    blurb:
      "Joanna's experience in the US and Australian financial services, including her time at Wealthfront where she helped manage $60 billion in assets for over 800,000 clients, is invaluable to our product strategy.",
  },
]
</script>
