import { computed, Ref, ref } from 'vue'
import { defineStore } from 'pinia'
import liabilitiesService from '@/services/v3/LiabilitiesService'
import { Liability, LiabilityType } from '@/types/Liability'
import createDefaultDebtObject from '@/composables/createDefaultDebtObject.ts'
import { DebtRecommendation } from '@/types/Debt/DebtRecommendationType'

export interface ILiabilityStore {
  liabilities: Liability[]
  liability: Liability | null
  liabilityErrors: Record<string, string>
  loadingStates: Record<string, boolean>
  fetchLiabilities: () => Promise<void>
  fetchLiability: (id: number) => Promise<void>
  createLiability: (liabilityData: Partial<Liability>) => Promise<Liability>
  updateLiability: (liabilityData: Partial<Liability>) => Promise<Liability>
  removeLiability: (id: number) => Promise<void>
  setDefaultLiability: (debtType: LiabilityType) => void
}

export const useLiabilityStore = defineStore('liabilityStore', () => {
  const liabilities = ref<Liability[]>([])
  const currentLiability = ref<Liability | null>(null)
  const recommendation = ref<DebtRecommendation>()
  // Refactor: Use an object to track loading states of different actions
  const loadingStates = ref({
    fetchingAll: false,
    fetchingSingle: false,
    creating: false,
    updating: false,
    removing: false,
  })
  const liabilityErrors = ref({})

  const debtTotal = computed(() => {
    return liabilities.value.reduce((total, liability) => total + liability.balance, 0)
  })
  const repaymentTotal = computed(() => {
    const frequencyMap = {
      monthly: 12,
      fortnightly: 26,
      weekly: 52,
    }
    return liabilities.value.reduce(
      (total, liability) =>
        total +
        (liability.repayment_amount * frequencyMap[liability.payment_frequency ?? 'monthly']) / 12,
      0,
    )
  })

  const setDefaultLiability = (debtType: LiabilityType) => {
    currentLiability.value = createDefaultDebtObject(debtType)
  }

  const setLoading = (action: keyof typeof loadingStates.value, state: boolean) => {
    loadingStates.value[action] = state
  }

  const resetLiabilityErrors = () => (liabilityErrors.value = {})

  const fetchLiabilities = async () => {
    setLoading('fetchingAll', true)
    try {
      const response = await liabilitiesService.getAllLiabilities()
      liabilities.value = response?.data || []
    } catch (error) {
      console.error('Failed to fetch liabilities', error)
      liabilities.value = []
    } finally {
      setLoading('fetchingAll', false)
    }
  }

  const fetchLiability = async (id: number) => {
    currentLiability.value = null
    setLoading('fetchingSingle', true)
    try {
      const response = await liabilitiesService.getLiability(id)
      currentLiability.value = response?.data || null
    } catch (error) {
      console.error(`Failed to fetch liability with id ${id}`, error)
    } finally {
      setLoading('fetchingSingle', false)
    }
  }

  const removeLiability = async (id: number) => {
    setLoading('removing', true)
    try {
      await liabilitiesService.deleteLiability(id)
      liabilities.value = liabilities.value.filter((liability) => liability.id !== id)
    } catch (error) {
      console.error(`Failed to remove liability with id ${id}`, error)
    } finally {
      setLoading('removing', false)
    }
  }

  const createLiability = async (liabilityData: Partial<Liability>) => {
    setLoading('creating', true)
    try {
      const response = await liabilitiesService.createLiability(liabilityData)
      liabilities.value.push(response?.data?.data)
      resetLiabilityErrors()
      return response?.data?.data
    } catch (error) {
      liabilityErrors.value = error.response.data.errors
      console.error('Failed to create liability', error)
    } finally {
      setLoading('creating', false)
    }
  }

  const updateLiability = async (liabilityData: Partial<Liability>) => {
    setLoading('updating', true)
    try {
      const response = await liabilitiesService.updateLiability(liabilityData)
      const index = liabilities.value.findIndex((liability) => liability.id === liabilityData.id)
      if (index !== -1) {
        liabilities.value[index] = { ...liabilities.value[index], ...response?.data?.data }
      }
      resetLiabilityErrors()
      return response?.data?.data
    } catch (error) {
      liabilityErrors.value = error.response.data.errors
      console.error('Failed to update liability', error)
    } finally {
      setLoading('updating', false)
    }
  }

  const fetchDebtRecommendation = async () => {
    setLoading('fetchingSingle', true)
    try {
      const response = await liabilitiesService.getDebtRecommendation()
      recommendation.value = response.data?.data
    } catch (error) {
      currentLiability.value = null
      console.error('Failed to fetch debt recommendation', error)
    } finally {
      setLoading('fetchingSingle', false)
    }
  }

  return {
    setLiability: (liability: Liability) => (currentLiability.value = liability),
    liabilities,
    liability: currentLiability as Ref<Liability>,
    recommendation,
    loadingStates,
    debtTotal,
    repaymentTotal,
    liabilityErrors,
    fetchLiabilities,
    fetchLiability,
    createLiability,
    updateLiability,
    removeLiability,
    setDefaultLiability,
    fetchDebtRecommendation,
    resetLiabilityErrors,
  }
})
