<template>
  <div class="w-full md:mt-10">
    <!-- Common content above the form -->
    <div class="rounded p-5 md:p-10 md:border-blue-3 md:border bg-white w-full">
      <h5 class="font-bold text-blue-1 w-full">
        To keep our advice up to date, please make sure your info is current.
      </h5>
      <Status
        v-if="!liability?.module_status || liability?.module_status === 'infoNeeded'"
        state="infoNeeded"
        status-style="outline"
        class="mt-5" />
      <form @input="(e) => setHasUnsavedChanges(e)" class="grid grid-cols-2 gap-5 mt-5">
        <component :is="formComponents[debtType]" @change="(e) => setHasUnsavedChanges(e)" />
      </form>
      <div class="grid md:grid-cols-2 w-full mt-10 gap-5">
        <div class="col-span-1 flex gap-5">
          <OtivoButton
            class="grow md:grow-0"
            colour="white"
            typography="button-2"
            data-test="debtSave"
            :loading="loading"
            @click="saveDebt">
            Save
          </OtivoButton>
          <InfoCircle class="self-center" :message="disclaimerMessage" />
        </div>
        <div class="col-span-1 flex items-center">
          <button
            v-if="route.params.id !== 'new'"
            class="mx-auto md:ml-auto md:mr-0 button-2 text-grey-3 flex items-center"
            data-test="debtRemove"
            @click="removeLoanModal">
            <TrashIcon class="mr-[8px]" />
            Remove
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, onBeforeMount, onMounted, onUnmounted, ref } from 'vue'
import { onBeforeRouteLeave, useRoute, useRouter } from 'vue-router'
import { useLiabilityStore } from '@/store/pinia/LiabilityStore'
import { useDebtFormLogic } from '@/composables/useDebtFormLogic.ts'
import OtivoButton from '@/components/OtivoButton.vue'
import Status from '@/components/Status.vue'
import { convertToKebabCase, convertToSnakeCase } from '@/utilities.ts'
import { useToast } from '@/composables/useToast.ts'
import { useModalStore } from '@/store/pinia/ModalStore.ts'
import ConfirmUpdateDebtModal from '@/views/Otivo/Dashboard/Debt/Components/ConfirmUpdateDebtModal.vue'
import UnsavedChangesModal from '../Modals/UnsavedChangesModal.vue'
import TrashIcon from '@/components/icons/TrashIcon.vue'
import ConfirmationModal from '@/components/Modals/ConfirmationModal.vue'
import { useModuleStatusesStore } from '@/store/pinia/ModuleStatusesStore.ts'
import CarLoanForm from '@/views/Otivo/Dashboard/Debt/Forms/CarLoanForm.vue'
import PersonalLoanForm from '@/views/Otivo/Dashboard/Debt/Forms/PersonalLoanForm.vue'
import HomeLoanForm from '@/views/Otivo/Dashboard/Debt/Forms/HomeLoanForm.vue'
import CreditCardForm from '@/views/Otivo/Dashboard/Debt/Forms/CreditCardForm.vue'
import InfoCircle from '@/components/InfoCircle/InfoCircle.vue'
import { Liability } from '@/types/Liability.ts'

const liabilityStore = useLiabilityStore()
const route = useRoute()
const router = useRouter()
const modalStore = useModalStore()
const { errorToast, successToast } = useToast()

const loading = ref(false)
const liability = computed(() => liabilityStore.liability)
const debtType = computed(() => liability.value?.type)
const recommendation = computed(() => liabilityStore.recommendation)
const hasUnsavedChanges = ref(false)

const isAdminPortal = computed(() => route.meta.isAdminPortal)

const overviewRoute = isAdminPortal.value ? 'AdminDebtDetailsOverview' : 'DebtDetailsOverview'
const detailsRoute = isAdminPortal.value ? 'AdminDebtDetails' : 'DebtDetails'
const planRoute = isAdminPortal.value ? 'AdminDebtOverview' : 'debtPlan'

onBeforeRouteLeave((to, from, next) => {
  if (hasUnsavedChanges.value) {
    showUnsavedChangesModal(next)
  } else {
    next()
  }
})

const setHasUnsavedChanges = (value?) => {
  const resetTarget = value?.target?.id?.split('-')[1] ?? null
  if (resetTarget) {
    liabilityStore.liabilityErrors[resetTarget] = []
  }
  hasUnsavedChanges.value = true
}

const showUnsavedChangesModal = (next?: Function) => {
  modalStore.openModal(UnsavedChangesModal, {
    heading: 'Unsaved Changes',
    leaveWithoutSaving: async () => {
      hasUnsavedChanges.value = false
      if (route.params.id !== 'new') await liabilityStore.fetchLiability(route.params.id)
      if (next) next()
    },
    continueEditing: () => {
      if (next) next(false)
    },
  })
}
const handleBeforeUnload = (e: BeforeUnloadEvent) => {
  if (hasUnsavedChanges.value) {
    e.preventDefault()
    e.returnValue = ''
  }
}

const formComponents = {
  credit_card: CreditCardForm,
  mortgage: HomeLoanForm,
  car_loan: CarLoanForm,
  personal_loan: PersonalLoanForm,
  investment: HomeLoanForm,
}

onMounted(() => {
  window.addEventListener('beforeunload', handleBeforeUnload)
  liabilityStore.resetLiabilityErrors()
})

onUnmounted(() => {
  window.removeEventListener('beforeunload', handleBeforeUnload)
})

onBeforeMount(async () => {
  if (route.params.id === 'new') {
    liabilityStore.setDefaultLiability(
      convertToSnakeCase(route.params.type as string) as Liability['type'],
    )
  }
})

const { handleSave, removeLoan } = useDebtFormLogic(liabilityStore)

const removeLiability = async (currentLiability: Liability) => {
  loading.value = true
  try {
    await removeLoan(currentLiability)
    await Promise.all([
      liabilityStore.fetchDebtRecommendation(),
      useModuleStatusesStore().fetchModuleStatuses(),
    ])
    liabilityStore.liability = null
    modalStore.closeModal()
    await router.push({ name: planRoute })
  } catch (e) {
    errorToast('Error removing loan')
  } finally {
    loading.value = false
  }
}

const removeLoanModal = () => {
  modalStore.openModal(ConfirmationModal, {
    heading: 'Remove loan',
    body: 'Are you sure you want to remove this loan?',
    confirmFunction: () => removeLiability(liability.value),
  })
}
const shouldUseDetailRoute = (res: Liability) =>
  res.balance == 0 || res.paid_monthly || res.interest_rate == 0

const updateLiability = async () => {
  loading.value = true
  const res = await handleSave(liability?.value)
  if (res) {
    liabilityStore.fetchDebtRecommendation().then(() => {
      liabilityStore.fetchLiability(res?.id)
      useModuleStatusesStore().fetchModuleStatuses()
      hasUnsavedChanges.value = false
      const routeName = shouldUseDetailRoute(res) ? detailsRoute : overviewRoute
      router.push({
        name: routeName,
        params: { id: res?.id, type: convertToKebabCase(res?.type) },
      })
    })
  }
  modalStore.closeModal()
  loading.value = false
}

const saveDebt = async () => {
  if (route.params.id === 'new') {
    try {
      loading.value = true
      const res = await handleSave(liability?.value)
      // some fucking error handling.
      if (res?.id) {
        successToast('Debt added successfully')
        hasUnsavedChanges.value = false
        liabilityStore.setLiability(res)
        liabilityStore
          .fetchDebtRecommendation()
          .then(() => {
            useModuleStatusesStore().fetchModuleStatuses()
            const routeName = shouldUseDetailRoute(res) ? detailsRoute : overviewRoute
            router.replace({
              name: routeName,
              params: { id: res.id, type: convertToKebabCase(res.type) },
            })
          })
          .catch(() => {
            errorToast('Error fetching debt recommendation')
          })
      }
    } catch (e) {
      console.error(e)
      errorToast('Error adding debt')
    } finally {
      loading.value = false
    }
  } else if (
    recommendation.value?.debt?.stage > 1 &&
    hasUnsavedChanges.value &&
    liability?.value?.module_status !== 'infoNeeded'
  ) {
    modalStore.openModal(
      ConfirmUpdateDebtModal,
      {
        liability: liability.value,
        updateLiability: async () => {
          await updateLiability()
        },
      },
      'large',
    )
  } else {
    await updateLiability()
  }
}

const disclaimerMessage =
  "If your information is incomplete or incorrect, the advice we provide might not be suitable for you, so please check it's correct."
</script>
