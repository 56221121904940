<template>
  <!--  <div-->
  <!--    class="fixed top-0 left-0 bg-[rgba(0,0,0,0.6)] w-100% h-100% flex flex-col justify-center items-center">-->
  <div
    class="flex flex-col bg-blue-6 w-100% h-100% max-h-[700px] max-w-[800px] otivo-drop-shadow rounded-lg absolute transform top-50% left-50% -translate-x-50% -translate-y-50%">
    <div class="flex w-100% py-[34px] rounded-t-lg bg-blue-1 justify-center">
      <div
        class="rounded-xl w-[160px] bg-none border-[1px] h-[12px] box-border border-white self-center">
        <div
          class="bg-white rounded-xl h-100% transition-all duration-500"
          :class="`w-${percentageComplete}`"></div>
      </div>
    </div>
    <div
      v-if="allModuleQuestions.length"
      ref="scrollContainer"
      :class="errorMessage ? 'overflow-y-hidden' : 'overflow-y-scroll'"
      class="flex flex-col h-full w-full px-[20px] md:px-[40px] no-scrollbar items-center"
      data-test="questionContainer">
      <!-- Question Container -->
      <!-- Intersection Observer threshold sets the % of component visible needed to become active-->
      <!-- NOTE: staticTable components are currently excluded from masking -->
      <div
        v-for="(question, idx) in filteredQuestionArray"
        :id="`question-${question.id}`"
        :key="idx"
        ref="questionRefs"
        v-intersection-observer="[
          onIntersectionObserver,
          { root: scrollContainer, threshold: 0.8 },
        ]"
        :class="[
          {
            'opacity-[0.2] pointer-events-none':
              visibleComponent != question.id &&
              question.component !== 'staticTable' &&
              question.component !== 'table' &&
              question.component !== 'dependentsTable',
          },
          ['table', 'staticTable', 'dependentsTable'].some((value) => value === question.component)
            ? 'w-fit mx-auto'
            : 'max-w-[414px] w-100%',
        ]"
        :data-id="question.seedId"
        :data-test="`question-${question.seedId}`"
        class="flex flex-col min-h-full justify-center h-auto flex-shrink-0 transform transition-opacity duration-500 py-10"
        data-q="Question">
        <p
          v-if="question.explainerText !== '' && question.component !== 'staticTable'"
          class="paragraph-1 text-blue-1">
          {{ question.explainerText }}
        </p>

        <h5
          class="bold"
          v-if="question.mapped_field !== 'salary_sacrifice_info'"
          :class="question.explainerText === '' ? 'mt-0' : 'mt-[20px]'">
          {{ question.title }}
        </h5>

        <div v-else>
          <h5 class="bold" :class="question.explainerText === '' ? 'mt-0' : 'mt-[20px]'">
            {{ formatQuestionForSalarySacrifice(question.title) }}
            <span @click="goToSalarySacrifice" class="text-otivo-blue cursor-pointer"> first</span>.
          </h5>
        </div>

        <component
          class="self-center w-100% mt-[20px]"
          v-if="question.component !== 'info'"
          v-bind="getComponentProperties(question)"
          :is="componentImports[question.component]"
          ref="dynamicComponent"
          :soft-error="errorMessage ? '' : softError"
          :error-message="errorMessage"
          :explainer-text="question.component === 'staticTable' ? question.explainerText : ''"
          @selected="(val: Answer) => handleUpdateEvents(question.id, val, question.component)"
          @update:value="(val: Answer) => handleUpdateEvents(question.id, val, question.component)"
          @toggle="(val: Answer) => handleUpdateEvents(question.id, val, question.component)">
        </component>

        <template v-if="question.text1 !== ''">
          <div
            v-if="isValidateHTMLString(question.text1)"
            v-html="question.text1"
            class="paragraph-1 text-blue-1 mt-[20px]"></div>
          <p v-else class="paragraph-1 text-blue-1 mt-[20px]">
            {{ question.text1 }}
          </p>
        </template>

        <p
          v-if="errorMessage !== '' && question.component !== 'input'"
          class="button-1 text-center text-red-1 mt-[20px]">
          {{ errorMessage }}
        </p>

        <OtivoButton
          v-if="question.component !== 'buttonBox'"
          size="large"
          :loading="posting"
          class="w-100% max-w-[374px] self-center"
          :colour="errorMessage ? 'dark-red' : 'blue'"
          :class="question.text1 !== '' ? 'mt-[20px]' : 'mt-[40px]'"
          :disabled="errorMessage !== ''"
          @click="next(question.id, question.component)">
          {{ question.buttonText ? question.buttonText : nextButtonText }}
        </OtivoButton>

        <BeatLoader
          data-test="qLoading"
          v-if="question.component === 'buttonBox' && posting"
          class="self-center mt-[20px]"
          color="#0064FF" />
        <p v-if="question.text2 !== ''" class="paragraph-1 text-blue-1 mt-[20px]">
          {{ question.text2 }}
        </p>
      </div>
    </div>
    <div class="flex h-100% justify-center" v-else>
      <BeatLoader class="self-center" color="#0064FF" />
    </div>
  </div>
  <!--  </div>-->
</template>

<script setup lang="ts">
import BaseInput, { BaseInputProps } from '@/components/Inputs/BaseInput.vue'
import Autocomplete from '@/components/BasicInputs/Autocomplete.vue'
import OtivoButton from '@/components/OtivoButton.vue'
import BaseDateInput from '@/components/Inputs/BaseDateInput.vue'
import TabButtonRow from '@/components/Onboarding/TabButtonRow.vue'
import Dropdown from '@/components/BasicInputs/Dropdown.vue'
import { computed, nextTick, onMounted, Ref, ref } from 'vue'
import { getPostcodeData } from '@/composables/getPostcodeData.ts'
import { searchInvestmentOptions } from '@/composables/getInvestmentOptions.ts'
import { getSuperFundNames } from '@/composables/getSuperFundNames.ts'
import { getOccupationList } from '@/composables/getOccupationList.ts'
import { GenericObject } from '@/types/GlobalTypes.ts'
import SalaryInput from '@/components/Onboarding/SalaryInput.vue'
import { vIntersectionObserver } from '@vueuse/components'
import { Answer, TableQuestion } from '@/components/Onboarding/Table.type.ts'
import TableGenerator from '@/components/Onboarding/TableGenerator.vue'
import StackedTableGenerator from '@/components/Onboarding/StackedTableGenerator.vue'
import getApiInstance from '@/services/Api.ts'
import { AxiosResponse } from 'axios'
import BeatLoader from 'vue-spinner/src/BeatLoader.vue'
import { useSessionStore } from '@/store/pinia/SessionStore.ts'
import { useModalStore } from '@/store/pinia/ModalStore.ts'
import ModuleOtivoTable from '@/components/ModuleOtivoTable.vue'
import { getConcessionalHistoricalTableData } from '@/composables/getConcessionalHistoricalTableData.ts'
import { useGuidanceClientStore } from '@/store/pinia/GuidanceClientStore.ts'
import { useRouter } from 'vue-router'
import DependentsTableGenerator from '@/components/Onboarding/DependentsTableGenerator.vue'
import PercentageInput from '@/components/BasicInputs/PercentageInput.vue'

const router = useRouter()

type DynamicQuestion = {
  id: number
  seedId: number
  component:
    | 'autocomplete'
    | 'input'
    | 'percentageInput'
    | 'dob'
    | 'buttonBox'
    | 'dropdown'
    | 'salaryInput'
    | 'table'
    | 'info'
    | 'staticTable'
    | 'stackedTable'
    | 'dependentsTable'
  skipped?: boolean
  explainerText?: string
  title: string
  text1?: string
  text2?: string
  buttonText?: string
  inputType?: 'text' | 'number' | 'currency' | 'percent' | 'email' | 'phone'
  placeholderText?: string
  mapped_field?: string
  config?: {
    feedbackData?: Array<unknown>
    questionOptions?: Array<unknown>
    concessional?: boolean
    autocompleteType?: 'postcode' | 'occupation' | 'superfund' | 'investmentOptions'
    existingValue?: string | number | { value: number; superIncluded: 0 | 1 }
    static?: boolean
    tableQuestions?: Array<TableQuestion>
    tableRowExistingAnswers?: Array<Answer[]>
    validations?: {
      min?: {
        softValidation?: string | null
        value: number
      }
      max?: {
        softValidation?: string | null
        value: number
      }
    }
  }
  criteria_met: boolean
}

type Props = {
  onboardingModule: string
  onComplete?: () => void
}
const props = defineProps<Props>()
const api = getApiInstance()

// refs
const errorMessage = ref<string>('')
const softError = ref<string>('')
const posting = ref(false)
const answer = ref<Answer>() // current questions answered value
const currentQuestion = ref<DynamicQuestion>() // current question
const questionRefs = ref([]) // ui question refs

// contains all questions
const allModuleQuestions = ref<DynamicQuestion[]>([])

// contains all questions that have been answered, skipped and the current question
const answeredQuestions = ref<DynamicQuestion[]>([])

// what actually shows the questions
const filteredQuestionArray = computed(() => {
  return answeredQuestions.value.filter((question) => question.criteria_met)
})

const percentageComplete = computed(() => {
  if (answeredQuestions.value.length <= 1) return 0
  return (
    Math.round(
      ((answeredQuestions.value.length - 1) / (allModuleQuestions.value.length - 1)) * 100
    ) + '%'
  )
})

const endOfQuestions = computed(() => {
  return answeredQuestions.value.length === allModuleQuestions.value.length
})

const formatQuestionForSalarySacrifice = (title: string): string => {
  const keyword = '[:SALARY_SACRIFICE_LINK:]'
  const parts = title.split(keyword)
  const part1 = parts[0]

  return `${part1}`
}

const goToSalarySacrifice = () => {
  useModalStore().closeModal()
  router.push({ name: 'salarySacrificeContributions' })
}

onMounted(() => {
  if (props.onboardingModule) {
    getQuestions().finally(() => {
      answeredQuestions.value.push(allModuleQuestions.value[0])
      nextTick(() => {
        scrollQuestionIntoView()
      })
    })
  }
})

/***************************************
 * Scroll and Question visibility logic
 * *************************************/
const scrollContainer = ref() // used in template directive
const visibleComponent = ref()
const onIntersectionObserver = ([{ isIntersecting, target }]: any) => {
  const id = target.id.split('-')[1]
  if (isIntersecting) visibleComponent.value = id
}

/*** No longer called ***/
const findFirstUnansweredQuestion = () => {
  allModuleQuestions.value.some((question) => {
    // push the question
    answeredQuestions.value.push(question)

    // if the question is not an info question and it has not been skipped, then it is the first unanswered question
    if (question.component !== 'info' && question.criteria_met) {
      if (question.component === 'table' && !question.config?.tableRowExistingAnswers?.length)
        return true // continue
      else if (question.component !== 'table' && !question.config?.existingValue) return true // continue
    }
  })
}
const scrollQuestionIntoView = () => {
  if (questionRefs.value.length === 0) return
  const nextQuestionRef: HTMLElement = questionRefs.value[filteredQuestionArray.value.length - 1]
  nextQuestionRef.scrollIntoView({ behavior: 'smooth', block: 'center' })
}
const next = (questionId: number, questionComponent: string) => {
  const currentQuestion = answeredQuestions.value.find((question) => question.id === questionId)
  const excludedComponents = ['table', 'stackedTable', 'dependentsTable']
  if (currentQuestion && !excludedComponents.some((val) => val === questionComponent)) {
    if (!answer.value && currentQuestion.config?.existingValue !== undefined)
      // set the answer to the existing value if it exists
      answer.value = { questionId: questionId, answer: currentQuestion.config?.existingValue }
  }

  switch (questionComponent) {
    case 'table':
    case 'stackedTable':
    case 'dependentsTable':
      if (!answer.value) {
        answer.value = existingTableAnswers.value
      }
      postAnswer(answer.value)
      break
    case 'info':
      addNextQuestionThenScroll()
      break
    default:
      postAnswer(answer.value as Answer)
  }

  // handle end of questions
  if (questionComponent === 'info' && endOfQuestions.value) {
    answer.value = { questionId: questionId, answer: 'acknowledged' }
    postAnswer(answer.value as Answer).finally(() => {
      if (props.onComplete) props.onComplete()
      useModalStore().closeModal()
      return
    })
  }
}

const nextButtonText = computed(() => {
  if (errorMessage.value) return 'Error'
  if (endOfQuestions.value) return 'Let’s get started...'
  return 'Next'
})

const addNextQuestionThenScroll = () => {
  const length = answeredQuestions.value.length
  if (length < allModuleQuestions.value.length) {
    const next = allModuleQuestions.value[length]
    answeredQuestions.value.push(next)
    if (softError.value) softError.value = ''
    currentQuestion.value = next
    // if the next questions criteria is not met, keep adding questions until we get the next valid question
    if (!next.criteria_met) nextQuestion()
    nextTick(() => {
      scrollQuestionIntoView()
    })
  }
}

/*** *********************
 * Question/Answer logic
 *** *********************/

// grab the answer from emitted components, trigger next on button box click
// NOTE: it only triggers when questions are UPDATED, not if they carry an existing value
const handleUpdateEvents = (questionId: number, val: Answer, type: string) => {
  errorMessage.value = ''
  softError.value = ''
  checkForValidations(val)
  answer.value = { questionId: questionId, answer: val }
  if (type === 'buttonBox') next(questionId, type)
}

const checkForValidations = (questionAnswer: Ref<Answer>) => {
  const validations = currentQuestion.value?.config?.validations
  softError.value = ''
  if (validations) {
    const answerVal = questionAnswer.value ? questionAnswer.value : questionAnswer
    if (validations.min && answerVal <= validations.min.value)
      validations.min.softValidation ? (softError.value = validations.min.softValidation) : ''
    if (validations.max && answerVal >= validations.max?.value)
      validations.max.softValidation ? (softError.value = validations.max.softValidation) : ''
  } else return
}

const nextQuestion = () => {
  // add the next question to the array if you've answered the last question in the questionArray but not the last question (questions array)
  if (answer.value) addNextQuestionThenScroll()
}

const updateAnsweredQuestionsArrayCriteraMetValues = () => {
  // potential problem here with table updates
  answeredQuestions.value.forEach((question) => {
    const questionToUpdate = allModuleQuestions.value.find(
      (q) => q.id === question.id
    ) as DynamicQuestion
    if (questionToUpdate && questionToUpdate.criteria_met !== question.criteria_met) {
      question.criteria_met = questionToUpdate.criteria_met
    }
  })
}

/*** ******* ***
 * API GET/POST
 *** ******* ***/
const dynamicComponent = ref()
const existingTableAnswers = computed(() => {
  return dynamicComponent.value[dynamicComponent.value.length - 1].validRows
})
const postAnswer = async (answerObject: Answer = {} as Answer) => {
  const answeredQuestion =
    answeredQuestions.value.find((question) => question.id === answerObject.questionId) ?? null
  const answeredQuestionIndex = answeredQuestions.value.indexOf(answeredQuestion as DynamicQuestion) // see below
  posting.value = true

  return api
    .Post(`/module/${props.onboardingModule}/questions`, answerObject)
    .then((res) => {
      if (res) {
        // if the module has not been completed, update question info
        if (!res.data.message) allModuleQuestions.value = res.data as DynamicQuestion[]
        else {
          errorMessage.value = res.data.message
          return
        }

        // update answeredQuestions with updated critera_met value if the question has been answered
        if (answeredQuestionIndex !== answeredQuestions.value.length - 1) {
          updateAnsweredQuestionsArrayCriteraMetValues()
          // remove irrelevant questions now
          answeredQuestions.value = answeredQuestions.value.slice(0, answeredQuestionIndex + 1)
          nextQuestion()
        }

        if (answeredQuestions.value.length - 1 === answeredQuestionIndex) {
          // if the last question answered was the last question in the array
          nextQuestion()
          return
        }
      }
    })
    .catch((err) => {
      console.error('Post error', err)
    })
    .finally(() => {
      posting.value = false
      answer.value = undefined
    })
}
const getQuestions = async () => {
  const sessionStore = useSessionStore()
  const route = sessionStore.getWhitelabelData?.callcentre_portal ? '/cfs_module' : '/module'
  try {
    const res = (await api.Get(`${route}/${props.onboardingModule}/questions`)) as AxiosResponse
    allModuleQuestions.value = res.data as DynamicQuestion[]
  } catch (err) {
    console.error('error getting questions', err)
  }
}

/*** ******************************** ***
 * Mappings for components && composables
 *** ******************************** ***/
const componentImports: GenericObject<unknown> = {
  input: BaseInput,
  percentageInput: PercentageInput,
  autocomplete: Autocomplete,
  dob: BaseDateInput,
  buttonBox: TabButtonRow,
  dropdown: Dropdown,
  salaryInput: SalaryInput,
  table: TableGenerator,
  staticTable: ModuleOtivoTable,
  stackedTable: StackedTableGenerator,
  dependentsTable: DependentsTableGenerator
}

const autocompleteComposibles: GenericObject<unknown> = {
  postcode: getPostcodeData,
  investmentOptions: searchInvestmentOptions,
  superfund: getSuperFundNames,
  occupation: getOccupationList
}

const getComponentProperties = (question: DynamicQuestion) => {
  switch (question.component) {
    case 'autocomplete':
      return getAutocompleteProperties(question)
    case 'input':
      return getInputProperties(question)
    case 'percentageInput':
      return getInputProperties(question)
    case 'dob':
      return getDobProperties(question)
    case 'buttonBox':
      return {
        tabButtonData: question.config?.questionOptions,
        existingValue: question.config?.existingValue as string
      }
    case 'dropdown':
      return getDropDownProperties(question)
    case 'salaryInput':
      return {
        existingValue: {
          amount: question.config?.existingValue?.value,
          super: question.config?.existingValue?.superIncluded
        }
      }
    case 'staticTable':
      return getStaticTableProperties(question)
    case 'table':
    case 'stackedTable':
    case 'dependentsTable':
      return getTableProperties(question)
  }
}
const getAutocompleteProperties = (question: DynamicQuestion) => {
  if (!question.config) return {}
  const autocompleteType: string = question.config.autocompleteType ?? ''
  return {
    id: `autocomplete-${question.config?.autocompleteType}-${question.id}`,
    type: question.config?.autocompleteType,
    placeholder: question.placeholderText ?? '',
    getItems: autocompleteComposibles[autocompleteType],
    existingValue: question.config.existingValue
  }
}
const getInputProperties = (question: DynamicQuestion): BaseInputProps => {
  const validations = question.config?.validations
  return {
    value: question.config?.existingValue ?? null,
    name: `${question.inputType}-${question.id}`,
    type: question.inputType,
    placeholder: question.placeholderText ?? '',
    min: validations?.min?.value ?? undefined,
    max: validations?.max?.value ?? undefined
  }
}
const getDobProperties = (question: DynamicQuestion) => {
  return {
    value: question.config?.existingValue || '',
    name: `dob-${question.id}`
  }
}
const getDropDownProperties = (question: DynamicQuestion) => {
  return {
    items: question.config?.questionOptions || [],
    type: `dropdown-${question.id}`,
    existingItem: question.config?.existingValue ?? null
  }
}
const getTableProperties = (question: DynamicQuestion) => {
  if (!question.config || !question.config.tableQuestions) return
  const filteredQuestions = question.config.tableQuestions.filter(
    (question) => question.criteria_met
  )
  return {
    id: question.id,
    questions: filteredQuestions,
    existingAnswers: question.config.tableRowExistingAnswers,
    static: question.config.static ?? false
  }
}

const getStaticTableProperties = (question: DynamicQuestion) => {
  const accountId = useGuidanceClientStore().getGuidanceClientData.accountId as string
  // const tableType: string = question.config.tableType
  // const tableType = 'concessionalContributions'
  // update to check what data is needed from question
  const concessional = question.config?.concessional

  return {
    id: question.id,
    accountId: accountId,
    isConcessional: concessional,
    getData: getConcessionalHistoricalTableData,
    numberOfColumns: 3
  }
}

const isValidateHTMLString = (text: string) => {
  const tagRegex = /<(“[^”]*”|'[^’]*’|[^'”>])*>/

  return tagRegex.test(text)
}
</script>

<style scoped></style>
