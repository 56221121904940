<template>
  <div id="portal-overview" v-if="overviewMetrics">
    <div id="overview-tiles" class="w-full grid grid-cols-3 lg:grid-flow-col lg:auto-cols-fr gap-5 pt-5 pb-10">
      <div id="total-members" class="overview-tile !bg-blue-1 !border-blue-1">
        <div class="button-4 !text-white font-bold">
          TOTAL MEMBERS <span class="font-normal">(since inception)</span>
        </div>
        <h1 class="text-white mt-[10px]">
          {{ formatAsNumber(overviewMetrics.totalMembers) }}
        </h1>
      </div>
      <div id="total-members" class="overview-tile">
        <div class="button-4 font-bold">NEW MEMBERS</div>
        <h1 class="mt-[10px]">
          {{ formatAsNumber(overviewMetrics.newMembers) }}
        </h1>
      </div>
      <div id="total-members" class="overview-tile">
        <div class="button-4 font-bold">TOTAL LOGINS</div>
        <h1 class="mt-[10px]">
          {{ formatAsNumber(overviewMetrics.totalLogins) }}
        </h1>
      </div>
      <div id="total-members" class="overview-tile">
        <div class="button-4 font-bold">UNIQUE MEMBER LOGINS <span class="font-normal"></span></div>
        <h1 class="mt-[10px]">
          {{ formatAsNumber(overviewMetrics.uniqueMemberLogins) }}
        </h1>
      </div>
      <div v-if="showNotConsented" id="total-members" class="overview-tile">
        <div class="button-4 font-bold">MEMBERS NOT CONSENTED</div>
        <h1 class="mt-[10px]">
          {{ formatAsNumber(overviewMetrics.membersNotConsented) }}
        </h1>
      </div>
    </div>
    <div class="flex flex-col md:flex-row gap-[40px]">
      <div class="flex flex-col w-100% md:w-50% rounded-[20px] bg-white p-[40px] otivo-shadow">
        <div class="flex flex-row justify-between">
          <h5 class="text-blue-1 font-bold">Member growth</h5>
          <div class="gap-[5px] paragraph-3 flex place-self-center">
            Date: <span class="button-3">{{ range.start }}</span> to
            <span class="button-3">{{ range.end }}</span>
          </div>
        </div>
        <div class="w-100% mt-[40px] max-h-[400px]">
          <div class="flex">
            <canvas id="member-growth-chart"></canvas>
            <div id="legend" />
          </div>
        </div>
      </div>
      <div class="flex flex-col w-100% md:w-50% rounded-[20px] bg-white p-[40px] otivo-shadow">
        <div class="flex flex-row justify-between">
          <h5 class="text-blue-1 font-bold">Accumulative member growth</h5>
        </div>
        <div class="w-100% max-h-[450px] mt-[40px]">
          <div class="flex">
            <canvas id="accumulative-growth-chart"></canvas>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import formatAsNumber from '@/composables/formatAsNumber.ts'
import {
  ReportingChartData,
  useCallcentreReportingStore,
} from '@/store/pinia/CallcentrePortalReportingStore.ts'
import { computed, onMounted, ref, watchEffect } from 'vue'
import { Chart } from 'chart.js/auto'
import { drawGraph as drawlineGraph } from '@/composables/charts/base/lineChart.ts'
import { drawGraph } from '@/composables/charts/base/barChart.ts'
import updateGraph from '@/composables/charts/updateGraph.ts'
import { useSessionStore } from '@/store/pinia/SessionStore.ts'

import('chart.js')
const reportingStore = useCallcentreReportingStore()

const barChart = ref<Chart>()
const aggregateChart = ref<Chart>()
const range = computed(() => reportingStore.searchRange)

const overviewMetrics = computed(() => reportingStore.getOverviewMetrics)
const memberGrowthData = computed(() => reportingStore.getMemberGrowthData)
const accumulativeGrowthData = computed(() => reportingStore.getAccumulativeGrowthData)

const sessionStore = useSessionStore()
const showNotConsented = computed(
        // id 73 is CFS in production and staging, locals may vary
  () => sessionStore.getWhitelabelData.identifier === 'COLONIAL_FIRST_STATE' || reportingStore.getWhitelabelId == 73
)

const barChartConfig = {
  labels: memberGrowthData.value.labels,
  datasets: [
    {
      data: memberGrowthData.value.data,
      backgroundColor: '#0064FF',
      borderColor: '#0064FF',
      hoverBackgroundColor: '#0037A1',
      borderWidth: 1,
      borderRadius: Number.MAX_VALUE,
      borderSkipped: false,
      barThickness: 20,
      datalabels: {
        color: 'transparent',
      },
    },
  ],
}

const lineChartConfig = {
  labels: accumulativeGrowthData.value.labels,
  datasets: [
    {
      data: accumulativeGrowthData.value.data,
      backgroundColor: (context) => {
        const { ctx } = context.chart
        const gradient = ctx.createLinearGradient(
          context.chart.width / 1.1,
          0,
          context.chart.width,
          context.chart.height,
        )

        gradient.addColorStop(0.25, '#a8e0ff')
        gradient.addColorStop(1, 'rgba(215, 245, 255, 0)')
        return gradient
      },
      borderColor: '#0064FF',
      hoverBackgroundColor: '#0037A1',
      borderWidth: 5,
      borderRadius: Number.MAX_VALUE,
      borderSkipped: false,
      pointBackgroundColor: '#fff',
      pointRadius: 4,
      pointBorderColor: '#0064FF',
      pointBorderWidth: 2,
      fill: true,
      datalabels: {
        color: 'transparent',
      },
    },
  ],
}

watchEffect(() => {
  if (memberGrowthData.value.labels.length || accumulativeGrowthData.value.labels.length) {
    if (barChart.value)
      updateGraph<ReportingChartData>('member-growth-chart', memberGrowthData.value)
    if (aggregateChart.value)
      updateGraph<ReportingChartData>('accumulative-growth-chart', accumulativeGrowthData.value)
  }
})

const createGraphs = async () => {
  const bar = document.getElementById('member-growth-chart') as HTMLCanvasElement

  // passing empty array removes default plugins
  barChart.value = await drawGraph(barChartConfig, bar, [])
  const aggregate = document.getElementById('accumulative-growth-chart') as HTMLCanvasElement
  aggregateChart.value = await drawlineGraph(lineChartConfig, aggregate, [])
}

onMounted(() => {
  createGraphs()
})
</script>

<style scoped lang="scss">
#portal-reporting {
  .overview-tile {
    justify-content: space-between;
    color: var(--grey-1);
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    //box-shadow: 0px 20px 30px 0px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    border: 1px solid var(--blue-3);
    //min-width: 250px;
    .button-4 {
      color: var(--grey-2);
    }
  }
}
</style>
