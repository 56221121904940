<template>
  <div class="rounded-lg p-4" :class="[borderClass, shadowClass]">
    <div
      class="flex flex-grow justify-between py-2"
      :class="[{ 'cursor-pointer': isCollapsable }, { 'collapse-open': open }]"
      @click="headerClicked">
      <slot name="heading" />
      <ArrowWide
        :fill="arrowFill"
        v-if="isCollapsable"
        class="mr-4 transition-all duration-200 w-16px h-16px"
        :class="open ? 'transform rotate-0' : 'transform rotate-180'" />
    </div>
    <Transition name="slide-fade">
      <div v-show="open" class="transition slide-in pt-[10px]">
        <slot name="body" />

        <div class="flex">
          <div class="flex-1 max-w-70% text-left">
            <slot name="body-footer-left" />
          </div>

          <div class="md:flex-1 text-right">
            <slot name="body-footer-right" />
          </div>
        </div>
      </div>
    </Transition>
  </div>
</template>

<script lang="ts" setup>
import ArrowWide from '@/components/icons/ArrowWide.vue'
import { ref } from 'vue'

type Props = {
  defaultOpen?: boolean
  isCollapsable?: boolean
  borderClass?: string
  shadowClass?: string
  arrowFill?: string
}

const props = withDefaults(defineProps<Props>(), {
  defaultOpen: true,
  isCollapsable: true,
  borderClass: 'border border-grey-4',
  shadowClass: 'shadow-md',
  arrowFill: '#888888',
})

const open = ref(props.defaultOpen)

const headerClicked = () => {
  if (props.isCollapsable) {
    open.value = !open.value
  }
}
</script>

<style lang="scss" scoped>
.slide-fade-enter-active {
  animation: anim-slide-fade 0.5 ease-in;
}

.slide-fade-leave-active {
  animation: anim-slide-fade 0.5 reverse;
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateY(-20px);
  opacity: 0;
}
@keyframes anim-slide-fade {
  0% {
    opacity: 0;
    transform: translateY(-20%);
  }
  20% {
    opacity: 0;
  }
  50% {
    opacity: 1;
    transform: translateY(0%);
  }
  100% {
    opacity: 1;
    transform: translateY(0%);
  }
}
</style>
