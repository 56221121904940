<template>
  <div id="personal-pricing-tiles" class="mt-[40px] flex flex-col">
    <h1
      v-if="isPricingPage"
      class="text-center m-auto pt-12 pb-6 px-[20px] md:px-0 order-1"
      v-html="headerHtml" />
    <h3 v-else class="text-center m-auto pt-12 pb-6 px-[20px] md:px-0 order-1" v-html="headerHtml" />

    <p class="order-3 md:order-2 md:block mb-4 mx-4 md:text-center intro-1 px-[20px] md:px-0">
      We believe every Australian should have access to quality financial advice without breaking the bank.
      <br class="hidden md:block" />
      And with a <strong>{{ trialDays }}-day free trial</strong>, why not give it a spin to see the benefits for yourself?
    </p>

    <div class="pt-10 pb-20 order-2 md:order-3">
      <div class="relative m-auto w-[250px] text-center">
        <img :src="Majesty" alt="Majesty Lines" class="absolute m-auto left-0 right-0 top-0" />
        <h3 class="pt-[36px]" style="font-size: 40px">
          ${{ productDataObject.yearlyPrice }}
          <span class="paragraph-2 text-grey-3">/year</span>
        </h3>
        <div class="paragraph-2 text-grey-1 mt-[10px]">1 year plan billed annually</div>
        <div class="paragraph-2 text-grey-3">($13.75 per month)</div>
      </div>
    </div>

    <div class="order-4 grid md:grid-cols-2 gap-x-10 gap-y-5 px-5 md:m-auto md:w-[720px] mt-10 md:mt-0">
      <div v-for="(feats, index) in productDataObject.features" :key="index" class="flex gap-2.5">
        <component :is="feats.icon" class="min-w-[20px] w-[20px] h-[20px] mt-1" />
        {{ feats.text }}
      </div>
    </div>

    <div class="order-5">
      <OtivoButton class="m-auto mt-10" colour="red" @click="auth0.createAccount()">
        Start free trial
      </OtivoButton>
      <p class="text-center paragraph-4 text-grey-1 italic pt-10 pb-20 px-[20px] md:px-0">
        Prices are in AUD and include GST. Using Otivo might be tax deductible - ask your accountant
        for details.
      </p>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useStore } from 'vuex'
import { type Component, computed, defineAsyncComponent, markRaw, ref } from 'vue'
import Majesty from '@/components/SVGS/Majesty.svg?url'
import OtivoButton from '@/components/OtivoButton.vue'
import { useAuth0 } from '@/lib/AuthenticatorPlugin.ts'

const auth0 = useAuth0()
const store = useStore()
const getCurrentWhitelabelObject = computed(() => store.getters.getCurrentWhitelabelObject)

interface Props {
  isPricingPage: boolean
}

withDefaults(defineProps<Props>(), {
  isPricingPage: true,
})

const headerHtml =
  "One plan. One price.<br />Financial advice made <span class='svg-underline'>easy.</span>"

const productDataObject = ref({
  yearlyPrice: 165,
  monthlyPrice: 13.75,
  period: 'yearly',
  features: [
    {
      icon: markRaw(
        defineAsyncComponent(() => import('@/components/SVGS/BrandIcons/16px/default-plan.svg')),
      ) as Component,
      text: 'Enjoy full access to Otivo whenever you want for 12 months.',
    },
    {
      icon: markRaw(
        defineAsyncComponent(() => import('@/components/SVGS/BrandIcons/16px/save-2.svg')),
      ) as Component,
      text: 'Maximise your super with salary sacrifice advice.',
    },
    {
      icon: markRaw(
        defineAsyncComponent(() => import('@/components/icons/PlanIcon.vue')),
      ) as Component,
      text: 'Optimised super investments tailored to you.',
    },

    {
      icon: markRaw(
        defineAsyncComponent(() => import('@/components/SVGS/BrandIcons/16px/debt.svg')),
      ) as Component,
      text: 'Discover how to pay down your credit cards up to 4x faster.',
    },

    {
      icon: markRaw(
        defineAsyncComponent(() => import('@/components/SVGS/BrandIcons/SunOtivo.svg')),
      ) as Component,
      text: 'Advice on contributions to boost your super.',
    },
    {
      icon: markRaw(
        defineAsyncComponent(() => import('@/components/SVGS/BrandIcons/16px/debt.svg')),
      ) as Component,
      text: 'Get help on paying down your mortgage faster.',
    },
    {
      icon: markRaw(
        defineAsyncComponent(() => import('@/components/icons/InsuranceColouredIcon.vue')),
      ) as Component,
      text: 'Get the cover you need to feel peace of mind.',
    },

    {
      icon: markRaw(
        defineAsyncComponent(() => import('@/components/icons/OnlineCareIcon.vue')),
      ) as Component,
      text: 'Discover more about money matters with 24/7 access to our learn centre.',
    },
  ],
})
const trialDays = 7
</script>

<style lang="scss" scoped>
.pricing-boxshadow {
  box-shadow: 0 20px 30px rgba(51, 51, 51, 0.15);
}
</style>
