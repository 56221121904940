<template>
  <div>
    <span class="button-2 text-grey-1">Owner</span>
    <Dropdown
      class="w-full mt-[5px]"
      name="debt owner"
      place-holder="name"
      type="responsible"
      :existing-item="mapOwner(liability.owner)"
      :items="ownerOptions"
      @selected="(selected) => updateSelected(selected)" />
  </div>
</template>

<script setup lang="ts">
import Dropdown from '@/components/BasicInputs/Dropdown.vue'
import { useLiabilityStore } from '@/store/pinia/LiabilityStore.ts'
import { computed } from 'vue'
import { useDebtFormLogic } from '@/composables/useDebtFormLogic.ts'

const liabilityStore = useLiabilityStore()
const liability = computed(() => liabilityStore.liability)

const { ownerOptions, mapOwner } = useDebtFormLogic(liabilityStore)

const emit = defineEmits<(event: 'change', message: string) => void>()

const updateSelected = (selected) => {
  liability.value.owner = selected.value
  emit('change', 'Debt owner updated')
}
</script>

<style scoped></style>
