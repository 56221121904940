<template>
  <div class="col-span-2 md:col-span-1">
    <span class="button-2"
      >Remaining term <img v-if="liability.balance > 0" :src="AsteriskIcon" class="inline mb-2"
    /></span>
    <div class="flex flex-row gap-2 mt-[5px]">
      <BaseInput
        v-model:value="remainingTermYears"
        :disabled="liability.balance == 0"
        type="number"
        class="w-full"
        name="remaining-term-years"
        placeholder="0 years"
        data-test="debtRemainingTermYears"
        :error-message="liabilityErrors?.term?.[0] ? ' ' : ''" />
      <BaseInput
        v-model:value="remainingTermMonths"
        :disabled="liability.balance == 0"
        type="number"
        class="w-full"
        name="remaining-term-months"
        placeholder="0 months"
        data-test="debtRemainingTermMonths"
        :error-message="liabilityErrors?.term?.[0]" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted, ref, watch } from 'vue'
import { useRoute } from 'vue-router'
import BaseInput from '@/components/Inputs/BaseInput.vue'
import { useLiabilityStore } from '@/store/pinia/LiabilityStore.ts'
import AsteriskIcon from '@/components/SVGS/AsteriskIcon.svg?url'

const liabilityStore = useLiabilityStore()
const route = useRoute()

const liability = computed(() => liabilityStore.liability)
const liabilityErrors = computed(() => liabilityStore.liabilityErrors)

const remainingTermYears = ref(0)
const remainingTermMonths = ref(0)

const totalRemainingTermMonths = computed(() => {
  return remainingTermYears.value * 12 + remainingTermMonths.value
})

onMounted(() => {
  if (route.params.id === 'new') {
    liability.value.term = totalRemainingTermMonths.value
  }

  if (liability?.value?.term !== null && !isNaN(liability?.value?.term)) {
    remainingTermYears.value = Math.floor(liability.value.term / 12)
    remainingTermMonths.value = liability.value.term % 12
  }
})

watch(
  () => totalRemainingTermMonths.value,
  (newVal, oldValue) => {
    if (oldValue === newVal) return
    liability.value.term = newVal
  },
)

watch(
  () => liability.value.balance,
  () => {
    if (liability.value.balance === 0) {
      remainingTermYears.value = 0
      remainingTermMonths.value = 0
      liability.value.term = 0
    }
  },
)
</script>

<style scoped></style>
