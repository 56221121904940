<template>
  <div
    class="flex flex-col w-full md:rounded-[20px] bg-white otivo-shadow border-blue-4.5 border-t md:border px-5 py-10 md:p-10">
    <h5 class="bold text-blue-1">Manage my debts</h5>
    <Loader v-if="liabilityStore.loadingStates.fetchingAll" />
    <div class="bg-grey-5 rounded mt-10 p-10" v-else-if="liabilities.length === 0">
      <OtivoButton
        colour="white"
        class="m-auto"
        typography="button-2"
        data-test="add-debt"
        @click="addDebtModal">
        Add debt
      </OtivoButton>
    </div>
    <div class="flex flex-col gap-2.5 my-10" v-else>
      <div class="grid grid-flow-col auto-cols-fr text-blue-1">
        <div class="menu-1 col-span-5 md:col-span-2">Name</div>
        <div class="menu-1 col-span-2 md:col-span-1">Balance</div>
        <div class="hidden md:block menu-1 col-span-1">Interest % P.A.</div>
        <div class="hidden md:block menu-1 col-span-1">Monthly repayment</div>
        <div class="col-span-1"></div>
      </div>

      <DebtOverviewCard
        v-for="(liability, key) in liabilities"
        :key="key"
        :data-test="'debtOverviewCard-' + key"
        :liability="liability"
        :is-priority-debt="matchesRecommendation(liability)" />
      <div class="mt-5 px-3 grid grid-flow-col auto-cols-fr items-center text-blue-1">
        <div class="text-[12px] font-medium col-span-5 md:col-span-2">Total</div>
        <div class="col-span-1 button-1">{{ formatCurrency(totalDebtAmount) }}</div>
        <div class="col-span-1" />
        <div class="hidden col-span-1 md:block button-1">
          {{ totalRepaymentAmount ? formatCurrency(totalRepaymentAmount) : '-' }}
        </div>
        <div class="col-span-1" />
      </div>
      <OtivoButton
        v-if="!isAdminPortal"
        class="mt-10 w-full md:w-fit"
        colour="white"
        typography="button-2"
        data-test="add-debt"
        @click="addDebtModal">
        <PlusIconFilled class="inline-flex mr-1" fill1="otivo-blue" fill2="white" />
        Add debt
      </OtivoButton>
    </div>
  </div>
</template>

<script setup lang="ts">
import PlusIconFilled from '@/components/icons/PlusIconFilled.vue'
import OtivoButton from '@/components/OtivoButton.vue'
import AddDebtModal from '@/views/Otivo/Dashboard/Debt/Modals/AddDebtModal.vue'
import { useModalStore } from '@/store/pinia/ModalStore.ts'
import { computed } from 'vue'
import { useLiabilityStore } from '@/store/pinia/LiabilityStore.ts'
import DebtOverviewCard from '@/views/Otivo/Dashboard/Debt/Components/DebtOverviewCard.vue'
import { formatCurrency } from '@/lib/helpers'
import Loader from '@/components/Loader/Loader.vue'
import { useRoute } from 'vue-router'
import { useModuleStatusesStore } from '@/store/pinia/ModuleStatusesStore.ts'
import { Liability } from '@/types/Liability.ts'

interface Props {
  totalDebtAmount: number
  totalRepaymentAmount: number
}

withDefaults(defineProps<Props>(), {
  totalDebtAmount: 0,
})

const modalStore = useModalStore()
const liabilityStore = useLiabilityStore()
const route = useRoute()
const isAdminPortal = route.meta.isAdminPortal

const liabilities = computed(() => liabilityStore.liabilities)
const recommendation = computed(() => liabilityStore.recommendation)

const matchesRecommendation = (liability: Liability) => {
  return (
    recommendation.value?.debt.plan_id === liability.id &&
    useModuleStatusesStore().getStatuses.debtPlan !== 'infoNeeded' &&
    !liability.paid_monthly
  )
}

const addDebtModal = () => {
  modalStore.openModal(AddDebtModal, {}, 'medium', false, 'background-color: #f4faff')
}
</script>

<style scoped></style>
