<template>
  <div
    v-if="processingLegacy"
    class="bg-white w-100% sm:w-50% md:w-25% p-10 border-1px border-blue-4.5 rounded-lg z-99 otivo-drop-shadow fixed top-25% translate-y-25% right-50% z-100 translate-x-50%">
    <h3 class="text-otivo-blue text-center">Almost there...</h3>
    <ClipLoader class="my-5 mx-auto w-fit" color="var(--otivo-blue)" />
    <p class="button-1 text-center">Your free year is on the way...</p>
  </div>
  <div
    :class="processingLegacy ? 'blur' : ''"
    class="grid md:grid-cols-2 gap-10 sm:gap-20 justify-center h-full md:max-h-[600px] w-full max-w-[1140px]">
    <OrderSummary :payment="paymentType" class="w-full h-full" />
    <form @submit.prevent="confirmAndPay" class="w-full h-full flex flex-col px-5">
      <h6 class="mb-5">How would you like to pay?</h6>
      <div class="flex flex-row gap-x-[20px] p-2 bg-blue-5 rounded grow-0 mb-5">
        <BaseTabButton
          class="w-full grow-0"
          :is-active="paymentType === 'credit'"
          :option="{ label: 'Pay with my card', value: 'credit' }"
          @change="() => (paymentType = 'credit')" />
        <BaseTabButton
          class="w-full grow-0"
          :is-active="paymentType === 'coupon'"
          :option="{ label: 'I have a coupon code', value: 'coupon' }"
          @change="() => (paymentType = 'coupon')" />
      </div>
      <div class="md:min-w-[375px] grow justify-items-stretch" v-show="paymentType == 'credit'">
        <h6 class="text-blue-1 font-normal mb-5">Enter your card information</h6>
        <BaseInput
          class="mb-5"
          type="text"
          name="cardHolderName"
          v-model:value="cardHolderName"
          placeholder="Card holder name" />
        <div class="card-input-container mb-5 min-h-[38px]" v-show="requiresPayment">
          <div id="cardNumber-element" class="w-100% align-text-bottom" data-test="cardNumber">
            <!--Stripe.js injects the Card Element-->
          </div>
        </div>
      </div>
      <div
        class="flex flex-col grow md:min-w-[375px] justify-items-stretch"
        v-if="paymentType == 'coupon'">
        <BaseInput
          class="mb-5 grow-0"
          type="text"
          name="promoCode"
          v-model:value="code"
          @input="calculateDiscount()"
          placeholder="Code" />
      </div>
      <div class="grow mb-auto">
        <p
          class="otivo-error-message mx-auto transition-opacity duration-300 ease-in-out min-h-4 mb-5"
          :class="errorMessage ? 'opacity-100' : 'opacity-0'">
          {{ errorMessage }}
        </p>
        <p class="paragraph-3 mb-5" v-if="paymentType === 'credit' && product">
          By clicking 'Confirm and pay', you agree to the payment of ${{
            product?.yearly_price ?? 0
          }}
          {{ eligibleForTrial ? 'after your 7 day free trial has expired' : '' }}.
          <br />
          This amount is in AUD and includes GST. Using Otivo might be tax deductible - ask your
          accountant for details.
        </p>
      </div>

      <div class="mb-10 mt-auto">
        <OtivoButton class="w-full md:w-auto" type="submit" :loading="loading"
          >Confirm and pay
        </OtivoButton>
      </div>
    </form>
  </div>
</template>

<script setup lang="ts">
import ClipLoader from 'vue-spinner/src/ClipLoader.vue'
import OrderSummary from '@/components/CreateAccount/Paywall/Partials/OrderSummary.vue'
import { computed, onBeforeMount, onMounted, onUnmounted, ref } from 'vue'
import { loadStripe } from '@stripe/stripe-js/pure'
import { Stripe, StripeCardElement, StripeElements } from '@stripe/stripe-js'
import BaseInput from '@/components/Inputs/BaseInput.vue'
import OtivoButton from '@/components/OtivoButton.vue'
import api from '@/services/Api.ts'
import { AxiosError } from 'axios'
import { useUserStore } from '@/store/pinia/UserStore.ts'
import { user as Auth0User } from '@/lib/AuthenticatorPlugin.ts'
import { useRouter } from 'vue-router'
import { useDebounceFn } from '@vueuse/core'
import BaseTabButton from '@/components/Inputs/BaseTabButton.vue'
import { useProductStore } from '@/store/pinia/ProductStore.ts'

const cardHolderName = ref('')
const card = ref<StripeCardElement>()
const stripe = ref<Stripe>()

const userStore = useUserStore()
const productStore = useProductStore()
const { fetchProductDetails } = productStore
const user = computed(() => userStore.getUser)
const eligibleForTrial = computed(() => user.value?.subscriptions?.length === 0)
const paymentType = ref<'credit' | 'coupon'>('credit')
const product = computed(() => productStore.product)

const code = ref('')
const discount = ref(0)
const requiresPayment = computed(
  // () => product.value?.yearly_price
  //   ? product.value?.yearly_price - discount.value != 0
  //   : true
  () => paymentType.value === 'credit',
)

const calculateDiscount = useDebounceFn(() => {
  // dont calculate if code is not long enough
  console.log('calculating discount', code.value)
  if (!product.value || !code.value) {
    discount.value = 0
    return
  }

  if (code.value.length < 5) {
    discount.value = 0
    return
  }
  // get coupon details if valid
  let coupon = {
    amount_off: null,
    percent_off: 100,
  }
  // calculate discount either by amount or percentage
  if (coupon.amount_off) {
    discount.value = product.value?.yearly_price - coupon.amount_off
  }
  if (coupon.percent_off) {
    discount.value = product.value?.yearly_price * (coupon.percent_off / 100)
  }
}, 500)

const stripeElements = ref<StripeElements>()
const setupElements = () => {
  const elements: StripeElements = stripe.value!.elements({
    mode: 'payment',
    locale: 'en',
    amount: product.value?.yearly_price,
    currency: 'aud',
    payment_method_types: ['card'], // NOTE: hardcoding card only for the time being - we will want this to be configurable
  })

  stripeElements.value = elements

  card.value = elements.create('card')
  card.value.mount('#cardNumber-element')
}

const router = useRouter()
const processingLegacy = ref(false)

const emit = defineEmits<{
  (e: 'next', data: string): void
}>()

const loading = ref(false)
const errorMessage = ref()
const confirmAndPay = async () => {
  errorMessage.value = undefined
  /*****************
   Obfuscating for testing
   /***************** */
  loading.value = true

  try {
    let payment_method = ''

    if (requiresPayment.value) {
      const res = await stripe.value?.createPaymentMethod({
        type: 'card',
        card: card.value,
      })

      if (res?.paymentMethod?.id === undefined) {
        throw new Error('Failed to create payment method')
      }

      payment_method = res.paymentMethod.id
    }

    const sub = await api().Post('/v3/subscribe', {
      payment_method: payment_method,
      plan_id: product.value?.stripe_product_id,
      price_id: product.value?.stripe_price_id,
      promo_code: code.value ?? null,
    })

    if (sub.status === 200 || sub.status === 201) {
      emit('next', 'paid')
    } else {
      loading.value = false
      throw new Error('Failed to complete payment')
    }
  } catch (err) {
    console.error('Failed to complete payment', err)
    if (err instanceof AxiosError) {
      errorMessage.value =
        err?.response?.data.error.split(':')[1] ?? 'Failed to complete payment. Please try again.'
    } else {
      errorMessage.value = 'Failed to complete payment. Please try again.'
    }
    loading.value = false
  }
}

onBeforeMount(async () => {
  if (Auth0User.value?.legacy_user) {
    processingLegacy.value = true
    const sub = await api().Get('/v3/user/subscriptions')
    if (sub.data.subscriptions.length > 0) {
      sub.data.subscriptions.find((s: any) => {
        if (s.type === 'otivo_subscription_grandfathered') {
          router.push({ name: 'clientDashboard' })
        }
      })
    }
  }
})

onMounted(async () => {
  loading.value = true
  try {
    await Promise.all([
      !user.value.auth0id && userStore.fetchUser(),
      !product.value && fetchProductDetails(),
    ])

    if (user.value.active_subscription && user.value.subscriptions.length > 0) emit('next', 'paid')

    stripe.value =
      (await loadStripe(import.meta.env.VITE_STRIPE_PUBLISHABLE_KEY, {
        locale: 'en',
      })) ?? undefined

    if (stripe.value === undefined) throw new Error('Stripe failed to load')
    setupElements()
  } catch (err) {
    console.error('Stripe failed to load', err)
    errorMessage.value = 'Failed to load payment gateway. Please try again.'
  } finally {
    loading.value = false
  }
})

onUnmounted(() => {
  // refresh user so subscriptions are updated
  userStore.fetchUser()
  processingLegacy.value = false
})
</script>

<style lang="scss" scoped>
.card-input-container {
  @apply mt-5 flex flex-row  border-[1px] px-2 bg-white border-grey-field rounded-[5px] w-full items-center;
}

#cardCvc-element,
#cardExpiry-element,
#cardNumber-element {
  padding: 10px;
}

:deep(.inactiveTab) {
  @apply bg-blue-5 border-transparent rounded;
}

:deep(.activeTab) {
  @apply bg-white rounded;
}
</style>
