<template>
  <div id="otivo-wrapper">
    <LoadingPage v-if="loading" />
    <Maintenance v-else-if="forceMaintenanceMode" />
    <RouterView v-else />
    <BaseModal />
  </div>
</template>

<script setup lang="ts">
import initDone from '@/initDone.ts'
import BaseModal from '@/components/Modals/BaseModal.vue'
import LoadingPage from '@/views/LoadingPage.vue'
import { computed, inject, onBeforeUnmount, onUnmounted, watch } from 'vue'
import { Auth0Symbol, IAuth0Plugin } from '@/lib/AuthenticatorPlugin.ts'
import useInactivityTimer from '@/composables/inactivityTimeout.ts'
import { useSessionStore } from '@/store/pinia/SessionStore.ts'
import { createLDContext } from '@/composables/createLDContext.ts'
import Maintenance from '@/views/Otivo/Maintenance.vue'
import { cleanupLDClient, FeatureList, initLDClient, useLDFlag } from '@/lib/ldClient.ts'

const loading = computed(() => !initDone.value)
const forceMaintenanceMode = useLDFlag(FeatureList.MAINTENANCE_MODE, false)
const auth0 = inject(Auth0Symbol) as IAuth0Plugin
const inactivityTimer = useInactivityTimer(useSessionStore())
const sessionStore = useSessionStore()

const init = async () => {
  if (auth0.isAuthenticated.value) {
    inactivityTimer.start(60)
  }
  console.timeEnd('[TIMER] ready')

  //   const route = useRoute()
  //   console.log('route', route)
  //   if (route.query.companyName && validSurmountCompany(route.query.companyName)) {
  //     store.commit('SET_FITNESS_TEST_COMPANY_NAME', route.query.companyName)
  //     store.dispatch('setSignUpCompanyName', route.query.companyName)
  //   } else {
  //     store.commit('SET_FITNESS_TEST_COMPANY_NAME', '')
  //     store.dispatch('setSignUpCompanyName', null)
  //   }

  const context = await createLDContext(sessionStore.getWhitelabelData.name)
  try {
    initLDClient(import.meta.env.VITE_LAUNCH_DARKLY_CLIENT_SIDE_ID, context)
  } catch (e) {
    console.error(e)
  }
}

onUnmounted(() => {
  inactivityTimer.stop()
})

watch(
  () => initDone.value,
  () => {
    init()
  },
)

onBeforeUnmount(() => {
  cleanupLDClient()
  inactivityTimer.stop()
})
</script>

<style lang="scss"></style>
