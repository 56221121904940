<template>
  <div>
    <div class="relative rounded-md shadow-sm min-w-[100px] w-full">
      <input
        type="number"
        id="percentageInput"
        step="0.01"
        min="0.00"
        max="100.00"
        :placeholder="props.placeholder"
        v-model.number="percentage"
        @input="formatPercentage"
        :class="[
          disabled
            ? 'bg-grey-4 cursor-not-allowed'
            : 'bg-white enabled:hover:otivo-outline enabled:focus:otivo-outline enabled:active:otivo-outline',
          props.errorMessage ? 'text-red-dark enabled:otivo-outline-error' : 'border-grey-field',
        ]"
        class="py-[10px] pr-[15px] border-1px rounded-[5px] text-button-3 w-full pl-5" />
      <p v-if="props.errorMessage" class="otivo-error-message absolute right-0">
        {{ props.errorMessage }}
      </p>
      <div
        class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3 border-left-1px">
        <div
          :class="props.errorMessage ? 'text-red-1' : 'text-grey-500'"
          class="font-bold sm:text-lg mx-auto">
          %
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { onMounted, ref } from 'vue'
import { watchDebounced } from '@vueuse/core'

export type BaseInputProps = {
  value: number
  placeholder?: number | null
  min?: number
  max?: number
  errorMessage?: string
  softError?: string
  disabled?: boolean
}

const props = withDefaults(defineProps<BaseInputProps>(), {
  placeholder: null,
  min: 0,
  max: 100,
  errorMessage: '',
  softError: '',
  disabled: false,
})

onMounted(() => {
  percentage.value = props.value
})

const emits = defineEmits<{
  (e: 'update:value', val: string | number): void
  (e: 'keyPress', val: string | number): void
  (e: 'cleared'): void
}>()

const percentage = ref<number>()

const formatPercentage = () => {
  if (typeof percentage.value === 'number') {
    if (percentage.value > 100) percentage.value = 100
    const num = Number(percentage.value)
    emits('update:value', parseFloat(num.toFixed(2)))
  }
}
watchDebounced(
  () => props.value,
  () => (percentage.value = props.value),
  { debounce: 500, maxWait: 1000 },
)
watchDebounced(percentage.value, formatPercentage, { debounce: 500, maxWait: 1000 })
</script>

<style scoped>
/* Hide arrow buttons in number inputs */
input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}
</style>
