<template>
  <BaseView>
    <div id="otivo-super-report">
      <Breadcrumbs />
      <div class="flex flex-col px-5 md:px-auto justify-center w-full bg-blue-6">
        <div class="max-w-[720px] mx-auto">
          <h1
            class="w-full text-4xl text-center pt-10 md:pt-20"
            v-html="pageContent.heading"
            v-if="pageContent?.heading" />
          <h5 class="text-center py-[40px]" v-html="pageContent.subHeading" />
          <div
            class="flex flex-col-reverse md:flex-row w-full gap-10 justify-center items-center md:items-start">
            <!-- Section 1 (OtivoSuperReportForm) - Centered and to the right of the image -->
            <div class="w-full">
              <OtivoSuperReportForm ref="otivoSuperReportForm" />
            </div>
            <!-- Section 2 - Image (on the left) -->
            <div class="w-full h-full object-cover pb-5 md:pb-auto">
              <img :src="coverImage" alt="Otivo Super Report" class="shadow-xl" />
            </div>
          </div>
          <div class="pb-[80px] pt-[40px] flex justify-center">
            <OtivoButton
              :loading="loading"
              colour="blue"
              class="w-full md:w-auto mt-6"
              @click="submitDownloadForm()">
              Download the Otivo Super Report
            </OtivoButton>
          </div>
        </div>
      </div>
    </div>
  </BaseView>
</template>

<script setup lang="ts">
import BaseView from '@/views/BaseView.vue'
import Breadcrumbs from '@/components/Breadcrumbs/Breadcrumbs.vue'
import { computed } from 'vue'
import OtivoSuperReportForm from './OtivoSuperReportForm.vue'
import { ref } from 'vue'
import coverImage from '@/assets/img/otivo-super-report-cover.webp'
import OtivoButton from '@/components/OtivoButton.vue'
import { useHead } from '@unhead/vue'

const loading = ref(false)
const otivoSuperReportForm = ref(null)

useHead({
  title: 'Otivo’s Super Advice Report',
})

const submitDownloadForm = () => {
  if (otivoSuperReportForm.value) {
    otivoSuperReportForm.value.submitOtivoSuperReportForm()
  }
}

const pageContent = computed(() => ({
  heading: 'The Otivo Super Report',
  subHeading: `The <span class="font-bold">Otivo Super Report</span> explores the fundamental role digital financial
  advice and technologies should play in redefining the financial future of Australians.`,
}))
</script>
