<template>
  <BaseView>
    <div class="bg-blue-5 text-center" v-if="pageContent?.currentPage === 'contactPage'">
      <Breadcrumbs class="bg-blue-5" />
      <div class="fix-width-m-auto py-8">
        <h1 class="px-[20px] md:px-0">
          {{ pageContent.contactHero.heading }}
        </h1>
        <p class="intro-1 py-6 px-[20px] md:px-0">
          {{ pageContent.contactHero.subHeading }}
        </p>
        <img :src="Collaboration" alt="collaboration" class="m-auto my-4 w-full md:w-[540px]" />
        <div class="m-6 md:m-12 intro-1" v-html="pageContent.contactHero.footer" />
      </div>
      <BigOffsetCurve fill="white" />
      <div class="bg-white py-[40px] md:pt-[0px] md:pb-[60px]">
        <div class="fix-width-m-auto text-center">
          <h2 class="px-[20px] md:px-0">
            {{ pageContent.contactFormHeadings.heading }}
          </h2>
          <p class="intro-1 py-[40px] px-[20px] md:px-0">
            {{ pageContent.contactFormHeadings.subHeading }}
          </p>
          <GorgiasContactForm class="m-auto md:w-50% px-[20px]" />
        </div>
      </div>
      <ContactAddress class="py-16 px-[20px]" />
      <ThreeTileDisplay
        top-curve-fill="otivo-blue"
        bottom-curve-fill="otivo-blue"
        class="!bg-otivo-blue"
        text-colour="white"
        fill1="white"
        fill2="white"
        :icon-boxes="pageContent.threeTileDisplay.iterableItems" />
    </div>
  </BaseView>
</template>

<script lang="ts" setup>
import BaseView from '../../../BaseView.vue'
import BigOffsetCurve from '@/components/Shapes/BigOffsetCurve.vue'
import Breadcrumbs from '@/components/Breadcrumbs/Breadcrumbs.vue'
import Collaboration from '@/components/icons/Collaboration.svg?url'
import ContactAddress from '@/components/BrandComponents/ContactUs/ContactAddress.vue'
import GorgiasContactForm from '@/components/BrandComponents/ContactUs/GorgiasContactForm.vue'
import ThreeTileDisplay from '@/components/BrandComponents/Otivo/ThreeTileDisplay.vue'
import { computed, onBeforeMount } from 'vue'
import { useStore } from 'vuex'
import { useHead } from '@unhead/vue'

const store = useStore()

onBeforeMount(() => store.dispatch('ContentStore/getContent', 'contactPage'))
const pageContent = computed(() => store.getters['ContentStore/pageContent'])

useHead({
  title: 'Otivo. Be better off.',
})
</script>
