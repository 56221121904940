<template>
  <BaseView>
    <div id="super-funds" v-if="pageContent?.currentPage === 'superFundsPage'">
      <Breadcrumbs />
      <LandingPageModule
        class="items-center md:pb-[80px] blue-6"
        background-color="blue-6"
        :landing-page-content="pageContent.superFundsHero"
        :show-button="true"
        :show-logos="false"
        button-listener="showContactUsModal"
        @button-clicked="showContactUsModal">
        <template #svgComponent>
          <img
            alt="two people working through their super together"
            src="https://store.otivo.com/HomePageImages/super_screen.svg" />
        </template>
      </LandingPageModule>
      <div class="bg-white w-full px-5 md:px-auto">
        <TrustedBy />
      </div>
      <SuperFundReport
        class="flex justify-center w-full bg-blue-1 h-full min-h-[500px] px-[20px] md:px-[40px] py-[80px]" />
      <RegularCurve class="h-6 w-full bg-blue-1 z-20" fill="otivo-blue" />

      <SuperFundAccordionSlideshow />
      <RegularCurve
        class="h-6 w-full hidden md:block bg-white z-20"
        fill="otivo-blue"
        :rotate="true" />
      <RegularCurve class="h-6 w-full md:hidden bg-otivo-blue z-20" fill="white" />
      <USPs
        @button-clicked="showContactUsModal"
        :usp-data="pageContent.superFundsUsps"
        :icons="uspsIcons"
        fill1="otivo-blue"
        fill2="otivo-red"
        class="py-20 bg-white" />
      <SuperFundFacts
        class="py-16 px-[20px] md:px-0 !bg-blue-5 !text-grey-1"
        :super-fund-facts="pageContent.superFundsFacts" />
      <RegularCurve class="h-6 w-full md:hidden bg-blue-1" fill="blue-5" :rotate="true" />
      <ThreeTileDisplay
        class="bg-blue-1"
        text-colour="white"
        :icon-boxes="pageContent.threeTileDisplay.iterableItems" />
      <RegularCurve class="h-6 w-full md:hidden bg-blue-1 fill-midnight" fill="midnight" />
      <HowWeWorkItOutModal
        @close="showHowWeWorkItOutModal = false"
        v-if="showHowWeWorkItOutModal" />
    </div>
  </BaseView>
</template>

<script setup lang="ts">
import BaseView from '@/views/BaseView.vue'
import Breadcrumbs from '@/components/Breadcrumbs/Breadcrumbs.vue'
import HowWeWorkItOutModal from '@/components/BrandComponents/Otivo/HowWeWorkItOutModal.vue'
import LandingPageModule from '@/components/BrandComponents/LandingPageModule.vue'
import PlanIcon from '@/components/icons/PlanIcon.vue'
import SuperFundFacts from '@/components/BrandComponents/Otivo/SuperFundPage/SuperFundFacts.vue'
import ThreeTileDisplay from '@/components/BrandComponents/Otivo/ThreeTileDisplay.vue'
import USPs from '@/components/BrandComponents/Otivo/USPs.vue'
import { computed, onBeforeMount, ref } from 'vue'
import { useStore } from 'vuex'
import SuperFundBrandLogos from '@/components/BrandComponents/Otivo/SuperFundPage/SuperFundBrandLogos.ts'
import SuperFundReport from '@/components/BrandComponents/Otivo/SuperFundPage/SuperFundReport.vue'
import SuperFundAccordionSlideshow from '@/components/BrandComponents/Otivo/SuperFundPage/SuperFundAccordionSlideshow.vue'
import LockIcon from '@/components/icons/LockIcon.vue'
import MonthlySpendDetailIcon from '@/components/icons/MonthlySpendDetailIcon.vue'
import Secure from '@/components/icons/Secure.vue'
import MoneyBagIcon from '@/components/icons/MoneyBagIcon.vue'
import EditIcon from '@/components/icons/EditIcon.vue'
import RegularCurve from '@/components/Shapes/RegularCurve.vue'
import LogoTicker from '@/components/LogoTicker.vue'
import TrustedBy from '@/components/BrandComponents/Otivo/TrustedBy/TrustedBy.vue'
import { useHead } from '@unhead/vue'

const store = useStore()
const brandLogos = ref(SuperFundBrandLogos)

onBeforeMount(() => {
  store.dispatch('ContentStore/getContent', 'superFundsPage')
})

useHead({
  title: "Otivo's digital advice solutions for super funds",
})

const pageContent = computed(() => store.getters['ContentStore/pageContent'])
const showContactUsModal = () => {
  store.dispatch('renderContactUsModal', true)
}
const showHowWeWorkItOutModal = ref(false)

const uspsIcons: Array<unknown> = [
  PlanIcon,
  LockIcon,
  MonthlySpendDetailIcon,
  Secure,
  MoneyBagIcon,
  EditIcon,
]
</script>

<style lang="scss" scoped>
@keyframes scroll {
  to {
    transform: translate(calc(-50% - 0.5rem));
  }
}
</style>
