<template>
  <div>
    <div
      class="bg-otivo-red rounded-t text-white p-1.5 flex flex-shrink w-42 button-2"
      data-test="debtPriorityFlag"
      v-if="isPriorityDebt">
      <AlertLightning />
      Pay off this debt first
    </div>
    <div
      :class="isPriorityDebt ? 'rounded-b rounded-tr' : 'rounded'"
      class="grid grid-flow-col auto-cols-fr gap-2 border border-grey-4 shadow-black/15 shadow items-center cursor-pointer px-[10px] py-[20px] paragraph-1"
      @click="handleManage">
      <div class="flex items-center gap-[10px] col-span-5 md:col-span-2">
        <component :is="liabilityIcon" />
        <h6 class="font-bold truncate w-full">{{ liability.name }}</h6>
      </div>
      <div class="col-span-2 md:col-span-1">
        {{ formatCurrency(liability.balance) }}
      </div>
      <div class="col-span-1 hidden md:block">
        {{ formatPercentage(liability.interest_rate) }}
      </div>
      <div class="col-span-1 hidden md:block" v-if="liability.paid_monthly">
        Paid monthly
      </div>
      <div class="col-span-1 hidden md:block" v-else>
        {{ formatCurrency(convertToMonthlyRepayment) }}
      </div>
      <div class="flex flex-row gap-2 col-span-1 items-center justify-end">
        <div
          v-if="isLiabilityComplete"
          class="hidden md:block button-3 text-otivo-blue text-end">
          See details
        </div>
        <Status
          v-else
          state="infoNeeded"
          status-style="outline"
          :icon-only="isSmallScreen"
        />
        <ArrowWide class="w-[12px] h-[12px] transform rotate-90 flex-end" fill="otivo-blue" />
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { computed, defineProps } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { formatCurrency, formatPercentage } from '@/lib/helpers'
import { Liability } from '@/types/Liability'
import CreditCardAlternativeIcon from '@/components/icons/CreditCardAlternativeIcon.vue'
import CarLoanAlternativeIcon from '@/components/icons/CarLoanAlternativeIcon.vue'
import PersonalLoanIcon from '@/components/icons/PersonalLoanIcon.vue'
import HomeLoanIcon from '@/components/icons/HomeLoanIcon.vue'
import InvestmentLoanIcon from '@/components/icons/InvestmentLoanIcon.vue'
import ArrowWide from '@/components/icons/ArrowWide.vue'
import Status from '@/components/Status.vue'
import { useLiabilityStore } from '@/store/pinia/LiabilityStore.ts'
import AlertLightning from '@/components/icons/AlertLightning.vue'
import {useMediaQuery}   from '@vueuse/core'


const isSmallScreen = useMediaQuery('(max-width: 768px)')
// Icon and route mapping based on liability type
const iconMap = {
  credit_card: CreditCardAlternativeIcon,
  car_loan: CarLoanAlternativeIcon,
  mortgage: HomeLoanIcon,
  investment: InvestmentLoanIcon,
  personal_loan: PersonalLoanIcon
}

// Computed icon based on liability type
const liabilityIcon = computed(() => {
  return iconMap[props.liability.type] || null
})

const convertToMonthlyRepayment = computed(() => {
  const frequency = props.liability.payment_frequency
  const repayment = props.liability.repayment_amount ?? 0
  const frequencyMap = {
    annually: 1,
    quarterly: 4,
    monthly: 12,
    fortnightly: 26,
    weekly: 52
  }
  if (frequency === null) return 0
  return (repayment * frequencyMap[frequency]) / 12
})

// Props definition
interface Props {
  liability: Liability
  isPriorityDebt?: boolean
}

const props = defineProps<Props>()
const router = useRouter()
const route = useRoute()

const isLiabilityComplete = computed(() => props.liability.module_status !== 'infoNeeded')
const isAdminPortal = computed(() => route.meta.isAdminPortal)

// Handle manage functionality with dynamic routing
const handleManage = () => {
  useLiabilityStore().setLiability(props.liability)
  const routeType = props.liability.type?.replace(/_/g, '-')
  const overviewRoute = isAdminPortal.value ? 'AdminDebtDetailsOverview' : 'DebtDetailsOverview'
  const detailsRoute = isAdminPortal.value ? 'AdminDebtDetails' : 'DebtDetails'

  if (routeType && props.liability?.id) {
    router.push({
      // Repayment amount isn't set if the debt is created from other modules dynamic onboarding
      name: isLiabilityComplete.value ? overviewRoute : detailsRoute,
      params: { id: props.liability.id, type: routeType }
    })
  }
}
</script>
