<template>
  <div class="px-5 py-10 md:p-10">
    <h5 class="text-blue-1 bold">Your next step to be better off</h5>
    <Status v-if="computedStatus" :state="computedStatus" status-style="outline" class="mt-2.5" />
    <div class="flex flex-row gap-2 mt-5">
      <AlertLightning class="w-6 shrink-0 mt-2"></AlertLightning>
      <span
        class="intro-2" data-test="recommendation-body"
        v-if="props.debtRecommendation?.debt.body"
        v-html="props.debtRecommendation?.debt.body"></span>
    </div>
    <div class="pt-5">
      <DrawerContainer @toggle="showingWhyCopy = !showingWhyCopy" :open="showingWhyCopy">
        <template #header>
          <span class="mr-[10px]" data-test="recommendation-button"
            ><AdditionIcon v-if="!showingWhyCopy" /> <SubtractionIcon v-else /></span
          >Why we've made this recommendation
        </template>
        <template #drawer>
          <div class="flex flex-col pt-5 gap-5" data-test="recommendation-reason">
            <span
              class="paragraph-1"
              v-if="debtRecommendation.debt.reason"
              v-html="debtRecommendation.debt.reason"></span>
            <p v-if="props.debtRecommendation?.debt.why" class="button-1">
              How we landed on this number
            </p>
            <span
              class="paragraph-1"
              v-if="props.debtRecommendation?.debt.why"
              v-html="props.debtRecommendation?.debt.why"></span>
          </div>
        </template>
      </DrawerContainer>
    </div>
  </div>
</template>

<script setup lang="ts">
import AlertLightning from '@/components/icons/AlertLightning.vue'
import AdditionIcon from '@/components/icons/AdditionIcon.vue'
import DrawerContainer from '@/components/Inputs/DrawerContainer.vue'
import { computed, onMounted, ref } from 'vue'
import SubtractionIcon from '@/components/icons/SubtractionIcon.vue'
import { DebtRecommendation } from '@/types/Debt/DebtRecommendationType'
import Status from '@/components/Status.vue'
import { useLiabilityStore } from '@/store/pinia/LiabilityStore.ts'
import { useModuleStatusesStore } from '@/store/pinia/ModuleStatusesStore.ts'

interface Props {
  debtRecommendation: DebtRecommendation
}

const props = defineProps<Props>()
const showingWhyCopy = ref(false)
const liability = computed(() => useLiabilityStore().liability)

onMounted(() => {
  if (!liability.value && props.debtRecommendation?.debt?.plan_id) {
    useLiabilityStore().fetchLiability(props.debtRecommendation.debt.plan_id)
  }
})
const adviceModuleStatus = computed(() => useModuleStatusesStore().getStatuses)
const computedStatus = computed(
  () => liability.value?.module_status || adviceModuleStatus.value?.debtPlan,
)
</script>
